import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allowedEnv:
    process.env.REACT_APP_ENV === 'production' ||
    process.env.REACT_APP_ENV === 'uat',
  routes: ['/', '/contact-details', '/summary'],
  queryStrings: {},
  language: 'en',
  visitedRoutes: []
};

export const baseSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    visitRoute: (state, action) => {
      if (!state.visitedRoutes?.includes(action.payload)) {
        state.visitedRoutes.push(action.payload);
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase('reset', () => initialState);
  }
});

export const { setLanguage, visitRoute } = baseSlice.actions;

export default baseSlice.reducer;
