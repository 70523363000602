import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from 'hooks/ReduxHook';

export const useStepper = () => {
  const base = useAppSelector((state) => state.base);
  const { routes } = base;
  const location = useLocation();
  const navigate = useNavigate();

  const moveNext = () => {
    routes.map((item, index) => {
      if (item === location.pathname) {
        navigate(routes[index + 1]);
      }
    });
  };

  const movePrev = () => {
    if (location.pathname !== '/') {
      routes.map((item, index) => {
        if (item === location.pathname) {
          if (routes[index - 1] !== '/') {
            navigate(routes[index - 1]);
          } else {
            navigate('/');
          }
        }
      });
    }
  };

  const isLastRoute = () => {
    return location.pathname === routes[routes.length - 1].route;
  };

  const isFirstRoute = () => {
    return location.pathname === routes[0].route || location.pathname === '/';
  };

  const moveToPage = (locationName, param) => {
    navigate(locationName, { state: param });
  };

  const moveToFirstPage = () => {
    redirectTo('/');
  };

  const redirectTo = (url) => {
    window.location.href = url;
  };

  return {
    moveToFirstPage,
    isFirstRoute,
    isLastRoute,
    moveNext,
    movePrev,
    moveToPage,
    redirectTo,
    route: location.pathname
  };
};
