import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStepper } from './Stepper';
import { visitRoute } from 'store/base';

export const useRouteSequence = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { routes, visitedRoutes } = useSelector((state) => state.base);
  const { moveToFirstPage } = useStepper();

  useEffect(() => {
    const currentIndex = routes?.indexOf(location.pathname);
    const isPaymentStatusRoute = location.pathname === '/payment-status';
    const isValidRoute = isPaymentStatusRoute || currentIndex !== -1;

    const hasVisitedPreviousRoutes = routes
      .slice(0, currentIndex)
      .every((route, index) => {
        return visitedRoutes[index] === route;
      });

    if (!isValidRoute || (currentIndex > 0 && !hasVisitedPreviousRoutes)) {
      moveToFirstPage();
    } else {
      dispatch(visitRoute(location.pathname));
    }
  }, [location, navigate, routes, visitedRoutes, dispatch, moveToFirstPage]);
};

export default useRouteSequence;
