import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  price: undefined,
  totalPrice: undefined,
  serviceFee: 2.5
};

export const priceSlice = createSlice({
  name: 'price',
  initialState,
  reducers: {
    setPrice: (state, action) => {
      state.price = action.payload.price;
    },
    setTotalPrice: (state, action) => {
      state.totalPrice = action.payload.totalPrice;
    }
  },
  extraReducers: (builder) => {
    builder.addCase('reset', () => initialState);
  }
});

export const { setPrice, setTotalPrice } = priceSlice.actions;

export default priceSlice.reducer;
