import React, { lazy } from 'react';

const Home = lazy(() => import('./Home'));
const ContactDetails = lazy(() => import('./ContactDetails'));
const Summary = lazy(() => import('./Summary'));
const PaymentStatus = lazy(() => import('./PaymentStatus'));

export const listOfRoutes = [
  { route: '/', component: Home, exact: true },
  { route: '/contact-details', component: ContactDetails, exact: true },
  { route: '/summary', component: Summary, exact: true },
  { route: '/payment-status', component: PaymentStatus, exact: true }
];
