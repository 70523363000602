export const vehicleType = {
  SALOON: {
    title: 'Saloon',
    info: 'Sedan / Hatchback / Wagon / Coupe / Convertible'
  },
  NON_SALOON: { title: 'Non-Saloon', info: 'MPV / SUV / Pick-up / Commercial' }
};

export const Fields = {
  TYPE: 'Vehicle Type',
  EMAIL: 'email'
};
