import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  regNo: undefined,
  make: undefined,
  capacity: undefined
};

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    setVehicle: (state, action) => {
      state.regNo = action.payload.regNo;
      state.make = action.payload.make;
      state.capacity = action.payload.capacity;
      state.vehTypeCode = action.payload.vehTypeCode;
    }
  },
  extraReducers: (builder) => {
    builder.addCase('reset', () => initialState);
  }
});

export const { setVehicle } = vehicleSlice.actions;

export default vehicleSlice.reducer;
