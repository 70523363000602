export const Fields = {
  VEHICLE_TYPE: 'Vehicle Type',
  ID_TYPE: 'ID Type',
  CAR_NUMBER: 'Car Registration Number',
  ID_NUMBER: 'ID Number',
  DOB: 'Date of Birth',
  AGE: 'Age',
  GENDER: 'Gender'
};

// export const VehicleType = {
//   CAR: 'car',
//   MOTOR: 'motor'
// };

export const idOptions = [
  { label: 'NRIC/MyKad', value: 'IC' },
  { label: 'Passport', value: 'PASSPORT' },
  { label: 'Army/Police/Others', value: 'ARMY/POLICE/OTHERS' },
  {
    label: 'Company Registration Number',
    value: 'COMPANY REGISTRATION NUMBER'
  }
];

export const Options = {
  IC: 'IC',
  PASSPORT: 'PASSPORT',
  ARMY: 'ARMY/POLICE/OTHERS',
  COMPANY: 'COMPANY REGISTRATION NUMBER'
};
