import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const roadtaxSlice = createSlice({
  name: 'roadtax',
  initialState,
  reducers: {
    setRoadTax: (state, action) => {
      state.roadtax = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase('reset', () => initialState);
  }
});

export const { setRoadTax } = roadtaxSlice.actions;

export default roadtaxSlice.reducer;
