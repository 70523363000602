import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getCookie } from 'utils/cookieStorage';

const authBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { endpoint }) => {
    if (endpoint !== 'saveRoadTax') {
      const token = getCookie('api_token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
        headers.set('X-Api-Key', process.env.REACT_APP_AUTH_KEY);
      }
    }
    headers.set('Content-Type', 'application/json');
    return headers;
  }
});

export const Services = createApi({
  reducerPath: 'Services',
  baseQuery: authBaseQuery,
  endpoints: (builder) => ({
    checkVehicle: builder.mutation({
      query: (data) => ({
        url: '/vixncd',
        method: 'POST',
        body: data
      }),
      providesTags: ['Vehicle']
    }),
    saveRoadTax: builder.mutation({
      query: (data) => ({
        url: '/roadtax',
        method: 'POST',
        body: data
      }),
      providesTags: ['RoadTax']
    }),
    paymentGateway: builder.mutation({
      query: (data) => ({
        url: `${process.env.REACT_APP_AWS_URL}payment/submit-to-pgw`,
        method: 'POST',
        body: data
      })
    })
  })
});

export const {
  useCheckVehicleMutation,
  usePaymentGatewayMutation,
  useSaveRoadTaxMutation
} = Services;
