import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const vixSlice = createSlice({
  name: 'vix',
  initialState,
  reducers: {
    setVehicleData: (state, action) => {
      state.vix = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase('reset', () => initialState);
  }
});

export const { setVehicleData } = vixSlice.actions;

export default vixSlice.reducer;
