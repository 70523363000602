import { createSlice } from '@reduxjs/toolkit';

import { Fields } from '../Constant';

export const initialState = {
  [Fields.TYPE]: 'Saloon'
};

export const contactDetailSlice = createSlice({
  name: 'contactDetail',
  initialState,
  reducers: {
    setFieldValue: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    }
  },
  extraReducers: (builder) => {
    builder.addCase('reset', () => initialState);
  }
});

export const { setFieldValue } = contactDetailSlice.actions;

export default contactDetailSlice.reducer;
