import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import VehicleFormReducer from 'components/SubComponents/VehicleForm/Logic/VehicleForm.reducer';
import VhlTypeReducer from 'components/SubComponents/VhlTypeContainer/Logic/VhlTypeContainer.reducer';
import ContactDetailReducer from 'pages/ContactDetails/Logic/ContactDetail.reducer';
import { Services } from 'services/api/services';
import BaseReducer from 'store/base';
import PriceReducer from 'store/response/price';
import RoadTaxReducer from 'store/response/roadtax';
import VehicleReducer from 'store/response/vehicle';
import VixReducer from 'store/response/vix';

const persistConfig = {
  key: 'roadtax',
  storage
};

const rootReducer = combineReducers({
  base: BaseReducer,
  vhlType: VhlTypeReducer,
  vehicleForm: VehicleFormReducer,
  vehicle: VehicleReducer,
  contactDetail: ContactDetailReducer,
  price: PriceReducer,
  vix: VixReducer,
  roadtax: RoadTaxReducer,
  [Services.reducerPath]: Services.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const setupStore = (preloadedState) => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            'persist/PERSIST',
            'persist/REHYDRATE',
            'persist/PAUSE',
            'persist/PERSIST',
            'persist/PURGE',
            'persist/REGISTER'
          ]
        }
      }).concat(Services.middleware),
    preloadedState
  });

  const persistor = persistStore(store);
  return { store, persistor };
};

export default setupStore;
