import { createSlice } from '@reduxjs/toolkit';

import { Fields } from '../Constant';

export const initialState = {
  [Fields.VEHICLE_TYPE]: undefined
};

export const vhlTypeSlice = createSlice({
  name: 'vhlType',
  initialState,
  reducers: {
    setFieldValue: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    resetVehicleState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase('reset', () => initialState);
  }
});

export const { resetVehicleState, setFieldValue } = vhlTypeSlice.actions;

export default vhlTypeSlice.reducer;
