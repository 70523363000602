import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

const resources = {
  en: {
    translation: {
      pos_insure: 'Pos Insurance',
      app_title: 'Pos Insurance',
      home_title: 'Road Tax renewal \n',
      home_title_2: 'in 3 steps',
      home_title_3: "Let' go! \n Select your vehicle type",
      drive_car: 'I drive a car',
      drive_motor: 'I ride a motorcycle',
      home_caption_1:
        'Renew road tax online with Pos Malaysia \nwith seamless update in MyJPJ app.',
      home_link_1: 'Why Pos? Read about us',
      welcome: 'Welcome to React and react-i18next',
      dynamic_val: 'hello, This is dynamic value : {{number_of_days}}',
      comp_title_1: 'Choose an \n insurance plan that \n',
      comp_title_2: 'suits you',
      comp_title_3: 'Enjoy no claims discount \nup to 55%.',
      comp_title_3_1: 'Comprehensive',
      comp_title_3_2: 'Third Party',
      comp_title_3_3: 'Third Party (Fire and Theft)',
      comp_link: 'NEED TO KNOW MORE? READ OUR FAQ',
      add_ons_title_1: 'Maximize ',
      add_ons_title_2: 'your \n',
      add_ons_title_3: 'protection with \nAdd-ons',
      add_ons_label_1: 'Cover for Windscreens, Windows and Sunroof',
      add_ons_label_2: 'Special Peril Coverage',
      add_ons_label_3: 'Additional Drivers Coverage',
      add_ons_label_4: 'And other options',
      step_intro_1: 'Fast & easy,',
      step_intro_2: ' in just 3 steps',
      step_1: 'Step 1',
      step_2: 'Step 2',
      step_3: 'Step 3',
      step_title_1: 'Select your vehicle',
      step_title_2: 'Enter details',
      step_title_3: "Pay and you're done",
      step_info_1: 'Choose between car or motorcycle',
      step_info_2: 'Enter user information',
      step_info_3: 'Your road tax \nrenewal is done',
      pos_advantage: 'The Pos Advantage',
      advantage_title_1: 'Simplifying',
      advantage_title_1_2: 'the',
      advantage_title_1_3: ' renewal process \nfor you',
      advantage_title_3: 'Easily renew your road tax on the go',
      advantage_list_1: 'Renewal in 3 steps',
      advantage_list_2: 'Renew comfortably anywhere & anytime',
      advantage_list_3: 'Trust us to renew your road tax securely',
      advantage_list_4: 'Get an instant quote',
      claim_process_title: 'Claim Process',
      claim_process:
        "Need help? Here's the standard 4-step claim \nprocess for all insurance brands",
      claim_process_list_1: 'Contact Policy Vendor',
      claim_process_list_2: 'Towing and repair',
      claim_process_list_3: 'Damage assessment',
      claim_process_list_4: 'Claim settlement',
      chat_title_1: 'Still need help?',
      chat_title_2: 'CHAT WITH US',
      footer_title_1: 'COURIER',
      footer_title_2: 'FINANCE',
      footer_title_3: 'COMPANY',
      footer_title_4: 'HELP',
      footer_title_list1_1: 'Track',
      footer_title_list1_2: 'Get a Quote',
      footer_title_list1_3: 'SendParcel',
      footer_title_list1_4: 'Business',
      footer_title_list1_5: 'Shop',
      footer_title_list2_1: 'Pay Bills',
      footer_title_list2_2: 'Buy Insurance',
      footer_title_list2_3: 'JPJ / Road Tax',
      footer_title_list2_4: 'Invest in ASNB',
      footer_title_list3_1: 'About Us',
      footer_title_list3_2: 'Press Room',
      footer_title_list3_3: 'Investor Relations',
      footer_title_list3_4: 'Corporate Governance',
      footer_title_list3_5: 'Sustainability',
      footer_title_list3_6: 'Legal',
      footer_title_list4_1: 'Contact Us',
      footer_title_list4_2: 'FAQs',
      faq_title: 'Frequently Asked Questions',
      faq_1: 'Why should I renew my vehicle road tax with Pos Malaysia?',
      faq_2: 'Which type of vehicle road tax can be renewed at Pos Malaysia?',
      faq_3: 'How long does it take to renew my road tax?',
      faq_4: "Can I renew my road tax if my vehicle's insurance is expired?",
      faq_5: 'How will I receive my road tax confirmation?',
      faq_6: 'Can I renew my road tax if I have any outstanding summons?',
      faq_7:
        "What if my road tax renewal can't be processed for other reasons?",
      faq_8: 'How long does it take to receive a refund?',
      faq_9: 'Is there any processing fee for refunds?',
      faq_10:
        "Who do I contact if I have issues or didn't receive my road tax renewal?",
      faq_11:
        'Who can I contact for post sales enquiries or if I did not receive my road tax renewal within 2 working days?',
      faq_ans_1:
        'Pos Malaysia is an official partner of JPJ Malaysia for road tax renewal. Your renewal will be processed through official channels.',
      faq_ans_2:
        'We renew road tax for all private cars and motorcycles, except those used for e-hailing.',
      faq_ans_3:
        'It can take up to two (2) working days to process your renewal, not including weekends/public holidays.',
      faq_ans_4:
        'No, you need a valid vehicle insurance to renew your road tax. Please ensure that your insurance is up-to-date before attempting to renew your road tax.',
      faq_ans_5:
        "You'll get an email receipt immediately after payment is made. You'll receive a confirmation email within two (2) working days and you’ll be able to check the renewal status on the MyJPJ app.",
      nearest_post_office: 'nearest Post Office.',
      faq_ans_6:
        'You must pay all outstanding traffic fines before your road tax can be renewed. The system will check for any fines or blacklists during the renewal process.',
      faq_ans_7:
        "If we can't process your renewal for reasons other than a blacklist, we will refund the road tax payment to you.",
      faq_ans_8:
        "You'll receive confirmation for a refund in 7-14 business days.",
      faq_ans_9: 'No, there is no processing fee.',
      faq_ans_10:
        "If you haven't received your renewal within 2 working days, contact Pos Customer Service at 1-300-300-300 or ",
      faq_ans_11:
        'If you did not receive your road tax renewal within 2 working days or have further enquiries, please contact our Pos Customer Service dept for further assistance at 1-300-300-300 or',
      faq_footer: 'See more FAQ',
      v_f_title: "Ok, let's get to know you",
      v_f_reg_no: 'Vehicle Registration Number*',
      v_f_poscode: 'Postcode*',
      v_f_ic_type: 'ID Type*',
      v_f_ic_num: 'NRIC Number*',
      v_f_birthDate: 'Date of Birth*',
      v_f_gender: 'Gender*',
      v_f_gender_m: 'Male',
      v_f_gender_f: 'Female',
      v_f_marital: 'Marital Status *',
      v_f_marital_s: 'SINGLE',
      v_f_marital_m: 'MARRIED',
      v_f_mobile: 'Mobile Number*',
      v_f_b_label_1: 'By clicking “Next” button, you have read',
      v_f_b_label_2: 'and agreed to our ',
      v_f_b_label_3: 'Terms of Use ',
      v_f_b_label_4: ' Privacy Policy',
      v_f_b: 'Next',
      v_f_ic_error_1: 'Your NRIC number is required',
      v_f_ic_error_2: 'Please enter a valid NRIC number',
      v_f_ic_error_3: 'NRIC number max 12 characters',
      v_f_ic_error_4: 'NRIC number min 12 characters',
      v_f_passport_error_1: 'Your passport number is required',
      v_f_passport_error_2: 'Please enter a valid passport number',
      v_f_passport_error_3: 'Passport number max 15 characters',
      v_f_passport_error_4: 'Passport number min 6 characters',
      v_f_reg_no_error_1: 'Please enter a valid vehicle number',
      v_f_reg_no_error_2: 'Vehicle number is required',
      v_f_reg_no_error_3: 'Vehicle number max 10 characters',
      v_f_reg_no_error_4: 'Vehicle number min 2 characters',
      v_f_poscode_error_1: 'Postcode is required',
      v_f_poscode_error_2: 'Please enter a valid postcode number',
      v_f_poscode_error_3: 'Postcode max 5 characters',
      v_f_birthDate_error: 'Date of birth is required',
      v_f_gender_error: 'Gender is required',
      v_f_marital_error: 'Your marital status is required',
      v_f_mobile_error_1: 'Mobile number is required',
      v_f_mobile_error_2: 'Please enter a valid mobile number',
      v_f_mobile_error_3: 'Mobile number min 9 characters',
      variant_error:
        'We are unable to fetch your vehicle variant. Please visit your nearest Pos Malaysia outlet to perform the renewal instead.',
      allianze_car_comprehensive_1:
        'Losses or damages due to accident, fire and theft',
      allianze_car_comprehensive_2:
        'Third party death, bodily injury property damages ',
      allianze_car_comprehensive_3: 'Roadside assistance services',
      allianze_car_third_party_1: 'Third party death and bodily injuries',
      allianze_car_third_party_2: 'Third party property losses or damages',
      allianze_car_fire_theft_1: 'Losses or damages due to fire or theft',
      allianze_car_fire_theft_2: 'Third party death and bodily injuries',
      allianze_car_fire_theft_3: 'Third party property losses or damages',
      allianze_motor_comprehensive_1:
        'Losses or damages due to accident, fire and theft',
      allianze_motor_comprehensive_2:
        'Third party death or bodily injury and third party property damages',
      allianze_motor_comprehensive_3: 'Protection for special perils',
      allianze_motor_comprehensive_4:
        'Free Personal Accident (motorcycles with 250 cc & below)',
      allianze_motor_comprehensive_5: 'Roadside assistance services',
      allianze_motor_comprehensive_6: 'Special Peril Coverage',
      allianze_motor_comprehensive_7:
        'Hospital Income - Insured/ authorised rider and pillion rider',
      allianze_motor_comprehensive_8:
        'Death/Permanent Disablement for insured/ authorised rider and pillion rider',
      allianze_motor_third_party_1: 'Third party death and bodily injuries',
      allianze_motor_third_party_2: 'Third party property losses or damages',
      allianze_motor_third_party_3:
        'Hospital Income - Insured/ authorised rider and pillion rider',
      allianze_motor_third_party_4:
        'Death/Permanent Disablement for insured/ authorised rider and pillion rider',
      allianze_motor_fire_theft_1: 'Losses or damages due to fire or theft',
      allianze_motor_fire_theft_2: 'Third party death and bodily injuries',
      allianze_motor_fire_theft_3: 'Third party property losses or damages',
      zurich_g_car_comprehensive_1: 'Bodily Injury Insured',
      zurich_g_car_comprehensive_2: 'Cover Vehicle Insured',
      zurich_g_car_comprehensive_3: 'Third Party Bodily Injury',
      zurich_g_car_comprehensive_4: 'Cover Third Party Vehicle',
      zurich_g_car_comprehensive_5: 'Free Breakdown Towing up to 150KM ',
      zurich_g_car_comprehensive_6: 'RM200 for Accident Towing ',
      zurich_g_car_comprehensive_7: 'Authorised Driver',
      zurich_g_car_third_party_1: 'Third Party Bodily Injury',
      zurich_g_car_third_party_2: 'Third Party Vehicle',
      zurich_g_car_fire_theft_1: 'Third Party Bodily Injury',
      zurich_g_car_fire_theft_2: 'Third Party Vehicle',
      zurich_g_car_fire_theft_3: 'Vehicle from Fire and Theft loss',
      zurich_g_motor_comprehensive_1: 'Bodily Injury Insured',
      zurich_g_motor_comprehensive_2: 'Motorcycle Repair for Insured',
      zurich_g_motor_comprehensive_3: 'Bodily Injury Third Party',
      zurich_g_motor_comprehensive_4: 'Motorcycle Repair for Third Party',
      zurich_g_motor_comprehensive_5: 'Vehicle Theft loss',
      zurich_g_motor_comprehensive_6: 'All Rider',
      zurich_g_motor_third_party_1: 'Third Party Bodily Injury Only',
      zurich_g_motor_third_party_2: 'Motorcycle Repair for Third Party',
      zurich_g_motor_fire_theft_1: 'Third Party Bodily Injury Only',
      zurich_g_motor_fire_theft_2: 'Motorcycle Repair for Third Party',
      zurich_g_motor_fire_theft_3: 'Third Party due to Fire and Theft loss',
      zurich_t_car_comprehensive_1: 'Bodily Injury Insured',
      zurich_t_car_comprehensive_2: 'Cover Vehicle Insured',
      zurich_t_car_comprehensive_3: 'Third Party Bodily Injury',
      zurich_t_car_comprehensive_4: 'Cover Third Party Vehicle',
      zurich_t_car_comprehensive_5: 'Free Breakdown Towing up to 150KM ',
      zurich_t_car_comprehensive_6: 'RM200 for Accident Towing',
      zurich_t_car_comprehensive_7: 'Authorised Driver ',
      zurich_t_car_third_party_1: 'Third Party Bodily Injury.',
      zurich_t_car_third_party_2: 'Third Party Vehicle',
      zurich_t_car_fire_theft_1: 'Third Party Bodily Injury.',
      zurich_t_car_fire_theft_2: 'Third Party Vehicle.',
      zurich_t_car_fire_theft_3: 'Vehicle from Fire and Theft loss',
      zurich_t_motor_comprehensive_1: 'Bodily Injury Insured',
      zurich_t_motor_comprehensive_2: 'Motorcycle Repair for Insured ',
      zurich_t_motor_comprehensive_3: 'Bodily Injury Third Party',
      zurich_t_motor_comprehensive_4: 'Motorcycle Repair for Third Party',
      zurich_t_motor_comprehensive_5: 'Vehicle Theft loss',
      zurich_t_motor_comprehensive_6: 'All Rider',
      zurich_t_motor_third_party_1: 'Third Party Bodily Injury Only',
      zurich_t_motor_third_party_2: 'Motorcycle Repair for Third Party',
      zurich_t_motor_fire_theft_1: 'Third Party Bodily Injury Only',
      zurich_t_motor_fire_theft_2: 'Motorcycle Repair for Third Party',
      zurich_t_motor_fire_theft_3: 'Third Party due to Fire and Theft loss',
      tune_car_comprehensive_1: 'Damage to your vehicle due to accident',
      tune_car_comprehensive_2:
        'Loss/damage to your vehicle caused by Theft or Fire',
      tune_car_comprehensive_3: 'Damage to other parties’ property',
      tune_car_comprehensive_4: 'Death or injury to other parties',
      tune_car_comprehensive_5: 'Breakage of Windscreen',
      tune_car_comprehensive_6:
        '24-Hour Emergency Auto-Assist (Towing due to Accident & Breakdown)',
      tune_car_comprehensive_7: 'All Driver Coverage',
      tune_car_fire_theft_1:
        'Loss/damage to your vehicle caused by Theft or Fire',
      tune_car_fire_theft_2: 'Damage to other parties’ property',
      tune_car_fire_theft_3: 'Death or injury to other parties',
      tune_motor_comprehensive_1: 'Damage to your vehicle due to accident.',
      tune_motor_comprehensive_2:
        'Loss/damage to your vehicle caused by Theft or Fire',
      tune_motor_comprehensive_3: 'Damage to other parties’ property',
      tune_motor_comprehensive_4: 'Death or injury to other parties',
      tune_motor_comprehensive_5: 'All Rider Coverage',
      tune_motor_third_party_1: 'Damage to other parties’ property',
      tune_motor_third_party_2: 'Death or injury to other parties',
      rhb_car_comprehensive_1: 'Free 24/7 Emergency roadside assistance',
      rhb_car_comprehensive_2:
        'Free jumpstart, change of battery, tyre replacement service and more',
      rhb_car_comprehensive_3: 'Free towing service up to RM300 per breakdown',
      rhb_car_comprehensive_4: 'Free accident towing service up to RM200',
      rhb_car_fire_theft_1: 'Free accident towing service up to RM200',
      rhb_car_fire_theft_2: 'Vehicle Repair for Third Party',
      rhb_motor_comprehensive_1: 'Enjoy protection for all riders',
      rhb_motor_comprehensive_2:
        'Motorcycle Repair for Insured and Third Party',
      rhb_motor_comprehensive_3: 'Free accident towing service up to RM50',
      rhb_motor_comprehensive_4:
        'Protect Your motorcycle with RHBI add-ons benefits (special perils, liability to pillion)',
      rhb_motor_fire_theft_1: 'Enjoy protection for all riders',
      rhb_motor_fire_theft_2: 'Motorcycle Repair for Third Party',
      rhb_motor_fire_theft_3: 'Free accident towing service up to RM50',
      etiqa_car_comprehensive_1: 'Third party bodily injury and death',
      etiqa_car_comprehensive_2: 'Third party property loss or damage',
      etiqa_car_comprehensive_3:
        'Loss or damage to your own vehicle due to accidental fire, theft, or accident',
      etiqa_car_comprehensive_4:
        'All your authorised drivers are automatically covered',
      etiqa_car_comprehensive_5:
        'No more claims excess for drivers above 21 years old only',
      etiqa_car_comprehensive_6: 'Free towing service up to 200km',
      etiqa_car_comprehensive_7: '24-hour emergency road assistance',
      etiqa_car_comprehensive_8:
        'Mudharabah (Enjoy Profit Sharing for any no claim incident for previous year)',
      etiqa_car_fire_theft_1: 'Third party bodily injury and death',
      etiqa_car_fire_theft_2: 'Third party property loss or damage',
      etiqa_car_fire_theft_3:
        'Loss or damage to your own vehicle due to accidental fire, theft, or accident',
      etiqa_car_fire_theft_4:
        'Mudharabah (Enjoy Profit Sharing for any no claim incident for previous year)',
      etiqa_motor_comprehensive_1:
        'Motorcycle coverage for accidents, fire and theft, 3rd party coverage for death, injury and property damage',
      etiqa_motor_comprehensive_2: 'Choise of All rider or Single rider',
      etiqa_motor_comprehensive_3: 'Free toeing service up to 50km',
      etiqa_motor_comprehensive_4: '24-hour emergency road assistance',
      etiqa_motor_comprehensive_5:
        'Mudharabah (Profit Sharing for any no claim incident for previous year',
      add_on: 'Add-Ons',
      addon_1: 'Special Peril Coverage',
      addon_2: 'Unlimited Drivers',
      addon_3: 'Additional Drivers',
      addon_4: 'Windscreen',
      addon_5: 'Legal Liability of Passengers for Negligent Acts',
      addon_6: 'Legal Liability to Passengers',
      addon_7: 'Unlimited Towing',
      addon_8: 'Private Hire Car (E-hailing)',
      addon_9: 'Waiver of Betterment',
      addon_10: 'Compensation for Assessed Repair Time',
      addon_11: 'Flood, Windstorm & Typhoon',
      addon_12: 'Compensation for Assessed Repair Time',
      allDrivers:
        'Waiver of compulsory excess for all drivers in the event of claim provided they hold a valid full relevant driving license and are above the age of 21 years at the time of incident.',
      wsc: 'Windscreen coverage allows you to recover the cost of repairing or replacing your vehicle’s windscreen. This additional benefit protects you from losing your NCD.',
      cvpp1cart:
        'Reasonable amount payable by third-party insurance companies as compensation for loss of use of the vehicle.',
      cvpp4cart:
        'Reasonable amount payable by third-party insurance companies as compensation for loss of use of the vehicle.',
      flood:
        'Covers loss or damage to your vehicle caused by flood, typhoon, hurricane, storm, tempest, volcanic eruption, earthquake, landslide, landslip, subsidence or sinking of the soil/earth or other convulsions of nature.',
      llop: 'Legal Liability of Passengers for Negligent Acts protects you against legal liability sought by third party against you for the action of your passenger(s) in your car provided that the passenger is not driving your car and other conditions set are satisfied.',
      lltp: 'Legal Liability to Passengers covers you against legal liability sought by your passenger(s) (except your own family members) against you in the event of an accident due to your negligence.',
      ehailing:
        'Provide “Additional Extended Covers” during the time the Car is On Call while providing an E-Hailing Service.',
      untow2: 'Unlimited towing distance up to limits specified in Malaysia.',
      waiver:
        'Waives the betterment charges in vehicle repair works due to an accident.',
      and: 'Waiver of compulsory excess for named driver in the event of claim provided they hold a valid full relevant driving license and are above the age of 21 years at the time of incident.',
      all_error_req:
        'We are unable to proceed with your request. Please try again later.',
      fl_error_sorry: 'Sorry!',
      fl_error_success: 'Success!',
      fl_error_close: 'Close',
      fl_error_done: 'Done',
      fl_error_retry: 'Retry',
      fl_error_refered: 'Refer to Pos office to do renewal.',
      mdm_postcode_error: 'Please enter a valid postcode.',
      pay_success: 'Your payment is successfull',
      insufficient_fund_error:
        'Your transaction has failed due to insufficient balance. Please top-up your account or use a different payment alternative to continue payment.',
      auth_failed_error:
        'We are unable to charge your card. Please use a different card or other payment method to continue payment.',
      user_abort: 'We are sorry to see you leave. Please try again.',
      fl_error_code_3_31:
        "Your policy is not due for renewal yet. You may only renew your policy within 2 months before it expires. We'll see you soon.",
      fl_error_code_8:
        "Your vehicle and personal details don't match. Please try again.",
      fl_error_code_9_34:
        'Vehicle number entered does not exist. Please enter the correct vehicle number.',
      fl_error_code_33:
        'Your vehicle is not applicable for online insurance renewal. Please visit the nearest Pos Malaysia outlet to continue with the renewal.',
      fl_error_code_35_77:
        'We are unable to renew your vehicle insurance online. Please visit the nearest Pos Malaysia outlet to continue with the renewal.',
      referrel_case:
        'We are unable to proceed with this specific vehicle type insurance renewal request. Please visit your nearest Pos Malaysia office to renew your vehicle insurance. ',
      faq_list_1:
        'Do I need to provide my physical IC (or any other forms of personal ID i.e passport) for online renewal?',
      faq_list_2: "What is 'NCD'?",
      faq_list_3: 'Why is the NCD quoted differently from my record?',
      faq_list_4: 'How do I determine the Sum Insured for my car?',
      faq_list_5: 'What is Market Value Coverage?',
      faq_list_6: 'What is Agreed Value Coverage?',
      faq_list_7: 'Can I change details in a policy?',
      faq_list_8:
        'What are the major exclusions for Comprehensive Motor Insurance?',
      faq_list_9:
        'What are the major exclusions for Comprehensive Motorcycle Insurance?',
      faq_list_10:
        'Does my insurance cover for me if I were to use my motorcycle for food delivery companies (e.g. Foodpanda, Grabfood, Shopeefood, etc.)?',
      faq_list_11: 'Is my motorcycle insurance valid outside of Malaysia?',
      faq_list_12: 'What is motor insurance detarrification?',
      faq_list_13: 'How do Insurance company determine their premium pricing?',
      faq_list_14: 'How will detarrification benefit you?',
      faq_list_15: 'Which Payment Gateway is used by Pos Malaysia?',
      faq_list_16:
        'My PC froze after I have performed the payment and my payment method was charged without successful payment notification. What should I do?',
      faq_list_17:
        'What happens if I have insufficient funds in my e-wallet, credit or debit cards?',
      faq_list_18:
        'I have made payment. How can I know that my payment is successful?',
      faq_list_19: 'Who do I call to enquire about my transaction status?',
      faq_list_20:
        'Is there a limit to the number of transactions or amount I can make in a day?',
      faq_list_21: 'Can I cancel the Insurance policy after payment?',
      faq_list_22: 'How do I get payment refund once my policy is cancelled?',
      faq_list_ans_1:
        'Generally, you do not need to provide your IC (Identification Card) number to renew your car/motorcycle insurance online, unless an insurer requests it for verification purposes, such as in cases where car details are incorrect or not detected in the JPJ (Road Transport Department) system.',
      faq_list_ans_2:
        "'No Claim Discount' or 'NCD' is a ‘reward’ scheme for you if no claim was made on your insurance policy on an annual basis. Different NCD rates are applicable for different classes of vehicles. For a private car, the scale of NCD ranges from 25% to 55% as provided in the policy.",
      faq_list_ans_3:
        'The NCD quoted is different if there is a claim lodged or if there is an NCD withdrawal to transfer to another car requested by the insured. Alternatively, you can also check your current NCD rate via \n https://www.mycarinfo.com.my/NCDCheck/Online.',
      faq_list_ans_4:
        'The sum to be insured should be based on the market value of your car. Market Value represents the value of your car with an allowance for wear and tear and/or depreciation.',
      faq_list_ans_5:
        'Market Value is the amount based on the insurance company estimate of what your car is worth in the open market. In the event that anything happens to your car, you can only claim as much as the current market value/worth of your car model at that point of time. Generally Market Value coverage premium tends to be lower.',
      faq_list_ans_6:
        'Agreed Value is the amount which has been agreed to between you and your insurance company. In the event that anything happens to your car, agreed value coverage can certainly provide the compensation you will receive from the insurance company if your car is written off or stolen. Generally Agreed Market coverage premium tends to be higher and subject to the individual insurance policy purchased.',
      faq_list_ans_7:
        'Please reach out to your insurer with the proof of the changes.',
      faq_list_ans_8:
        "This policy does not cover certain losses (subject to the respective insurer) such as: \n 1. Policyholder's death or bodily injury due to a motor accident; \n 2.Policyholder's liability against claims from passengers in your vehicle; and \n 3. Loss, damage or liability arising from an act of nature i.e. flood, storm or landslide.",
      faq_list_ans_9:
        "This policy does not cover certain losses such as: \n 1. Policyholder's death or bodily injury due to a motorcycle accident; \n2. Policyholder's liability against claims from others rider; and \n3. Loss, damage or liability arising from an act of nature i.e. flood, storm or landslide. \n(Note: This list is non-exhaustive. Please refer to the policy contract for the full list of exclusions under this policy.)",
      faq_list_ans_10:
        'Yes, we provide insurance coverage for those working with delivery services companies, including but not limited/restricted to Deliveroo, FoodPanda or GrabFood, etc.',
      faq_list_ans_11:
        'If you plan to travel with your motorcycle outside of Malaysia, you need to purchase a different insurance policy before crossing the border.',
      faq_list_ans_12:
        "Detariffication means removing the tariff structure (fixed price). This will allow insurance companies to charge premiums at a different price that correspond to its consumers' risk behaviour based on the business risks models and strategies. The detariffication of motor premiums offers more innovative and competitively priced motor products. \n Before 1st July 2017 - Premiums for motor products are subject to the same tariff rates based on engine capacity and location., Flexibility in pricing based on the risk behaviour of the policyholders.\n 1st July 2017 onward - All products were defined and subjected to tariff rates, Insurers have the flexibility to offer new products and features based on the risk of the policyholders.",
      faq_list_ans_13:
        'With the detariffication, every insurance company will have its own pricing models based on different characteristics of drivers and vehicles such as gender, driver’s age, usage of car, claims experience, occupation, vehicle make and model and other related factors.',
      faq_list_ans_14:
        'At a glance: Different premiums rates since it’s based on the driver profiles, business risk models and strategies. A wider range of risk-based pricing motor insurance products to cater to your needs. Leverage on insurer’s continuous improvement includes customer experience, service level, operational efficacy and marketing strategies.',
      faq_list_ans_15:
        'Our online payment service is provided and operated by KiplePay',
      faq_list_ans_16:
        'All successful transactions will receive a confirmation email that contains your purchase details and insurance policy. If you have not received any email, please call us at 1-300-300-300 or AskPos at www.pos.com.my/#AskPos for immediate assistance.',
      faq_list_ans_17: 'Your transaction will be rejected.',
      faq_list_ans_18:
        'Once the payment is successful, you will receive a notification on payment details and invoice via your given email address.',
      faq_list_ans_19:
        'Kindly call the bank or the eWallet provider to enquire about the payment status. For debit card transactions, you may check your internet banking portal.',
      faq_list_ans_20:
        'There is no limit in transaction amount or number per day, however the credit or debit card limit is subject to your available limit or balance in your credit or debit card account.',
      faq_list_ans_21:
        'Cancellation of the insurance policies are subject to the terms and conditions of the insurance provider. For further inquiries, please call Insurance provider below for answers.',
      faq_list_ans_22:
        'Payment cancellation related to insurance only will be directed to insured bank account by Insurer.',
      bck_button: 'Back',
      p_type: 'Policy Type',
      stepper1: 'Plans',
      stepper2: 'Add-Ons',
      stepper3: 'Customer Details',
      stepper4: 'Checkout',
      mandatory: '*mandatory',
      mandatory_desc:
        'Note: Please add a valid email address to receive your completed insurance policy.',
      policy_info: 'Policy Information',
      ncd_info_1: 'Hooray! You have ',
      ncd_info_2: 'No Claims Discount (NCD) on your renewal',
      info_pop:
        'No Claim Discount (NCD) is a discount offered by insurers if you (the insured) have not made any claims against your vehicle insurance policy in the past year.',
      policy_duration: 'Current Policy Duration',
      vehicle_info: 'Vehicle Information',
      vehicle_reg: 'Vehicle Registration Number',
      vehicle_make: 'Vehicle Make',
      vehicle_var: 'Confirm Vehicle Variant',
      engine_num: 'Engine Number',
      chasis_num: 'Chasis Number',
      cubic_cap: 'Cubic Capacity',
      prod_year: 'Production Year',
      your_vehicle: 'Is this your vehicle?',
      yes: 'Yes',
      no: 'No',
      plan: 'Plans',
      policy_type: 'Policy Type',
      comprehensive: 'Comprehensive',
      comprehensive_info:
        'Protects the policyholders’ vehicle in the event of a accident, fire and theft. This policy also provides coverage to the third party.',
      third_party_info:
        'Provides coverage only to the third party for the damage or loss to the third party and their property caused by your vehicle.',
      third_party_ft_info:
        'Covers only the damage and loss to the third party where you are at fault. It also provides coverage to policyholders’ vehicles in the event of fire and theft.',
      third_party: 'Third Party',
      third_party_ft: 'Third Party (Fire and Theft)',
      sort: 'Sort',
      default: 'Default (A-Z)',
      price_up: 'Price: Low to High',
      price_down: 'Price: High to Low',
      benefit_empty: 'benefits empty',
      Done: 'Done',
      your_saving: 'Your Savings:',
      error_no_info: 'Error; unable to receive information',
      sum_insured: 'Sum Insured',
      select_all: 'Select all recommended',
      driver: 'Drivers',
      coverage: 'Coverage',
      edit: 'Edit',
      premium_detail: 'Premium Details',
      coverage_period: 'Coverage Period: ',
      basic: 'Basic Premium/Contribution',
      gross: 'Gross Premium/Contribution',
      rebate: 'Rebate Amount',
      stamp_duty: 'Stamp Duty',
      total: 'Total',
      next: 'Next',
      as_of: 'As of',
      as_of_2: 'your coverage value is RM ',
      confirm_coverage: 'Confirm your preferred coverage value',
      market_value: 'Market Value',
      market_value_info:
        "Your insurer / takaful provider pays for your claims based on your car's market value at the time of claiming. The sum insured / covered is subject to depreciation.",
      additional_driver: 'Additional Drivers',
      additional_driver_info:
        'Waiver of compulsory excess for named driver in the event of claim',
      max_driver:
        'You are maximum number (3/3) of named drivers. Would you like to switch to',
      unlimited: 'Unlimited',
      add_driver: 'Add Driver',
      coverage_title: 'Cover for Windscreens, Windows and Sunroof',
      coverage_title_info:
        'Covers the cost to repair or replace any glass in the windscreen, window or sunroof (including the cost of lamination/tinting film, if any) of your car that is accidentally damaged.',
      coverage_title_info_2:
        'Please ensure that the coverage value is in between RM 500 to RM 5,000 only.',
      coverage_value: 'Coverage Value',
      payable_amt: 'My payable amount',
      confirm: 'Confirm',
      full_name: "Owner's Full Name",
      email: 'Email',
      mobile: 'Mobile Number',
      marital_status: 'Marital Status',
      current_address: 'Current Address',
      address: 'Address',
      postcode: 'Postcode',
      state: 'State',
      City: 'City',
      name_required: 'Name is required',
      email_required: 'Email is required',
      email_message: 'Please enter a vaid email',
      address_required: 'Address is required',
      confirm_customer: 'Here’s your confirmed Customer and Vehicle Details',
      vehicle_details: 'Vehicle Details',
      owner: "Owner's",
      pay: 'Pay',
      pay_info:
        "By clicking the 'Pay' button, you hereby expressly consent to share you Personal Data subject to the following",
      terms: 'Terms and Conditions',
      pay_info_2: "accept the Partner's, read and understood the",
      product_disclosure: 'Product Disclosure Sheet',
      product_disclosure2: 'Zurich Privacy Notice',
      pay_info_3:
        'by the Partner and acknowledge you do not have 2 or more claims in the past 3 years.',
      back: 'Back',
      renew_now: 'RENEW NOW',
      error1:
        'Sorry! We are unable to proceed with your request. Please try again later.',
      error2:
        "Sorry! Your vehicle and personal details don't match. Please try again.",
      error3:
        "Sorry! Your policy is not due for renewal yet. You may only renew your policy within 2 months before it expires. We'll see you soon.",
      error4:
        'Sorry! We are unable to renew your vehicle insurance online. Please visit the nearest Pos Malaysia outlet to continue with the renewal.',
      error5: 'Invalid postcode. Please enter a valid postcode.',
      error6: 'Sorry, there are no add-ons \nmatching with your options',
      error7: 'Sorry, there are no plans \nmatching with your options',
      error8:
        'Please use the back button below and not browser back button to ensure your previously entered fields are intact.',
      error9:
        'Do you want to go back? You will be redirected to the homepage and lose all the changes.',
      error10:
        'Sorry! Vehicle number entered does not exist. Please enter the correct vehicle number.',
      error11:
        'Sorry! Your vehicle is not applicable for online insurance renewal. Please visit the nearest Pos Malaysia outlet to continue with the renewal.',
      Remove: 'Remove',
      loading_msg: 'We’re finding the best \n insurance deals for you...',
      loading_otw:
        "Loading.. We're moving at full speed to drive you to the next destination.",
      pay_attention:
        "Please wait! Do not refresh the page or click the 'Back' or 'Close' button of your browser while we're processing the payment.",
      attention: 'Attention!',
      recommended_coverage: 'Recommended Coverage',
      lowest_coverage: 'Lowest Available Coverage',
      add_driver_name: 'Full Name*',
      add_driver_error1: 'This input is required.',
      add_driver_error2: 'This input min 3 characters',
      no_variant:
        "We're unable to fetch your vehicle variant. Please go to the nearest Post Malaysia branch to perform the insurance renewal.",
      expired:
        'Sorry, your session has expired due to inactivity. Please try again to continue.',
      not_available:
        'This add-on is not available for your vehicle. Please select others.',
      payment_msg_1: 'Thank you for your payment.',
      payment_msg_2:
        'A copy of the payment receipt will be sent to your email address. You will receive a separate email upon successful renewal with instruction to check the status via MyJPJ app.',
      payment_msg_3: 'cover note will be sent to your email within 10 minutes.',
      your_id: 'Your payment reference ID is',
      car_insurance_renewal: 'Car Insurance Renewal',
      motor_insurance_renewal: 'Motor Insurance Renewal',
      car_type_1: 'Looking for',
      car_type_2: 'Insurance Renewal',
      car_type_3: 'Make sure your motor insurance is renewed and active.',
      renew1: 'Have you renewed your vehicle insurance?',
      renew2:
        'Make sure your insurance is renewed and summons cleared before renewing road tax.',
      renew3: 'I would like to renew insurance first',
      renew4: 'Lets proceed with road tax renewal',
      renew5: 'There is no match for your Road Tax number',
      renew6: 'Please try again.',
      renew7: 'No, I would like to purchase insurance first',
      contact1: 'Please select your vehicle type',
      'Non-Saloon': 'Non-Saloon',
      Saloon: 'Saloon',
      contact2: 'Ok, tell us your contact details',
      contact3:
        'After transacting using this method, you MUST download and register your MyJPJ application.',
      contact4:
        'Your Road Tax will be available on your MyJPJ app within 2 working days.(For Malaysians, the renewed roadtax can be viewed via MyJPJ app only)',
      contact5: 'Download the MyJPJ App from the',
      or: 'or',
      price1: 'Road Tax Details',
      price2: 'Collection Method',
      Duration: 'Duration',
      year: 'year',
      Method: 'Method',
      price3: 'Digital Road Tax (e-LKM)​',
      price4: 'Service Fee',
      summary1: 'Here’s your confirmed Vehicle and Customer details',
      summaryTnC:
        "By clicking the 'Pay' button, you hereby expressly agree and consent to share you Personal Data subject to the following",
      summaryTnC2: 'Terms and Conditions',
      summaryTnC3:
        'I acknowledge and confirm that this vehicle is not used for e-hailing services, has no outstanding traffic summons, and meets all the necessary renewal requirements.',
      'Vehicle Owner’s Name': 'Vehicle Owner’s Name',
      'Vehicle Owner’s NRIC': 'Vehicle Owner’s NRIC',
      'Vehicle Owner’s Passport Number': 'Vehicle Owner’s Passport Number',
      'Vehicle Registration Number': 'Vehicle Registration Number',
      'Vehicle Details': 'Vehicle Details',
      'Mobile Number': 'Mobile Number',
      'Email Address': 'Email Address',
      'Vehicle Type': 'Vehicle Type',
      important: 'Important',
      try_again: 'Try again',
      start_over: 'Start over',
      'MPV / SUV / Pick-up / Commercial': 'MPV / SUV / Pick-up / Commercial',
      'Sedan / Hatchback / Wagon / Coupe / Convertible':
        'Sedan / Hatchback / Wagon / Coupe / Convertible',
      enter_full_name: 'Please enter full name',
      rt_breakdown: 'Road Tax Breakdown',
      askpos: 'AskPos'
    }
  },
  my: {
    translation: {
      pos_insure: 'Pos Insurans',
      app_title: 'Pos Insurans',
      home_title: 'Pembaharuan Cukai Jalan \n',
      home_title_2: 'dalam 3 Langkah',
      home_title_3: 'Mari kita mulakan! \n Pilih jenis kenderaan anda',
      drive_car: 'Saya memandu kereta',
      drive_motor: 'Saya menunggang motosikal',
      home_caption_1:
        'Perbaharui cukai jalan dalam talian dengan Pos Malaysia dengan kemas kini lancar dalam aplikasi MyJPJ',
      home_link_1: 'Kenapa Pos? Baca tentang kami',
      welcome: 'Selamat datang ke React and react-i18next',
      dynamic_val: 'hello, This is dynamic value : {{number_of_days}}',
      comp_title_1: 'Pilih pelan insurans yang ',
      comp_title_2: 'sesuai untuk anda',
      comp_title_3: 'Nikmati diskaun tanpa tuntutan \nsehingga 55%',
      comp_title_3_1: 'Komprehensif',
      comp_title_3_2: 'Pihak Ketiga',
      comp_title_3_3: 'Pihak Ketiga (Kebakaran dan Kecurian)',
      comp_link: 'Ingin tahu maklumat lanjut? Baca Soalan Lazim kami',
      add_ons_title_1: 'Maksimumkan ',
      add_ons_title_2: 'perlindungan anda ',
      add_ons_title_3: 'dengan\n Servis Tambahan',
      add_ons_label_1:
        'Perlindungan untuk Cermin Depan, Tingkap dan Cermin Bumbung',
      add_ons_label_2: 'Perlindungan Peril Khas',
      add_ons_label_3: 'Perlindungan Pemandu Tambahan',
      add_ons_label_4: 'Dan pilihan lain',
      step_intro_1: 'Cepat & mudah,',
      step_intro_2: ' dalam 3 langkah sahaja',
      step_1: 'Langkah 1',
      step_2: 'Langkah 2',
      step_3: 'Langkah 3',
      step_4: 'Langkah 4',
      step_title_1: 'Pilih kenderaan anda',
      step_title_2: 'Masukkan butiran',
      step_title_3: 'Bayar dan anda selesai!',
      step_info_1: 'Pilih antara kereta atau motosikal',
      step_info_2: 'Isikan maklumat pengguna anda',
      step_info_3: 'Bayaran untuk cukai jalan telah selesai',
      step_info_4: 'Pembaharuan insurans anda telah selesai',
      pos_advantage: 'Kelebihan Pos',
      advantage_title_1: 'Memudahkan',
      advantage_title_1_2: ' proses',
      advantage_title_1_3: ' pembaharuan untuk anda',
      advantage_title_3: 'Perbaharui cukai jalan anda dengan mudah',
      advantage_list_1: 'Pembaharuan dalam 3 langkah',
      advantage_list_2: 'Perkhidmatan yang boleh anda percayai',
      advantage_list_3: 'Perbaharui dengan selesa sentiasa',
      advantage_list_4: 'Dapatkan sebut harga segera',
      claim_process_title: 'Proses Tuntutan',
      claim_process:
        'Perlukan bantuan? Berikut adalah 4 langkah standard untuk proses tuntutan bagi semua jenama insurans',
      claim_process_list_1: 'Hubungi Vendor Polisi',
      claim_process_list_2: 'Tunda dan pembaikan',
      claim_process_list_3: 'Penilaian kerosakan',
      claim_process_list_4: 'Penyelesaian tuntutan',
      chat_title_1: 'Perlukan bantuan?',
      chat_title_2: 'HUBUNGI KAMI',
      footer_title_1: 'KURIER',
      footer_title_2: 'KEWANGAN',
      footer_title_3: 'SYARIKAT',
      footer_title_4: 'BANTUAN',
      footer_title_list1_1: 'Jejak',
      footer_title_list1_2: 'Dpatkan harga',
      footer_title_list1_3: 'SendParcel',
      footer_title_list1_4: 'Perniagaan',
      footer_title_list1_5: 'Kedai',
      footer_title_list2_1: 'Bayar Bil',
      footer_title_list2_2: 'Beli Insurans',
      footer_title_list2_3: 'JPJ / Cukai Jalan',
      footer_title_list2_4: 'Labur dalam ASNB',
      footer_title_list3_1: 'Tentang Kami',
      footer_title_list3_2: 'Bilik Akhbar',
      footer_title_list3_3: 'Hubungan Pelabur',
      footer_title_list3_4: 'Tadbir Urus Korporat',
      footer_title_list3_5: 'Kelestarian',
      footer_title_list3_6: 'Sah',
      footer_title_list4_1: 'Hubungi Kami',
      footer_title_list4_2: 'Soalan Lazim',
      faq_title: 'Soalan Lazim',
      faq_1:
        'Mengapa saya perlu memperbaharui cukai jalan kenderaan saya dengan Pos Malaysia?',
      faq_2:
        'Apakah jenis cukai jalan kenderaan yang boleh diperbaharui di Pos Malaysia?',
      faq_3:
        'Berapa lamakah masa yang diambil untuk memperbaharui cukai jalan saya?',
      faq_4:
        'Bolehkah saya memperbaharui cukai jalan jika insurans kenderaan saya tamat tempoh?',
      faq_5: 'Bagaimanakah saya akan menerima pengesahan cukai jalan saya?',
      faq_6:
        'Bolehkah saya memperbaharui cukai jalan saya jika saya mempunyai sebarang saman tertunggak?',
      faq_7:
        'Bagaimana jika pembaharuan cukai jalan saya tidak dapat diproses atas sebab lain?',
      faq_8: 'Berapa lama masa yang diambil untuk menerima bayaran balik?',
      faq_9:
        'Adakah terdapat sebarang bayaran pemprosesan untuk bayaran balik?',
      faq_10:
        'Siapa yang boleh saya hubungi jika saya menghadapi masalah atau tidak menerima pembaharuan cukai jalan saya?',
      faq_11:
        'Siapa yang boleh saya hubungi untuk pertanyaan lanjut atau jika saya tidak menerima pembaharuan cukai jalan dalam masa 2 hari bekerja?',
      faq_ans_1:
        'Pos Malaysia adalah rakan strategik rasmi JPJ Malaysia untuk pembaharuan cukai jalan. Pembaharuan anda akan diproses melalui saluran rasmi.',
      faq_ans_2:
        'Kami memperbaharui cukai jalan untuk semua kereta dan motosikal persendirian, kecuali yang digunakan untuk e-hailing.',
      faq_ans_3:
        'Proses pembaharuan cukai jalan boleh mengambil masa sehingga dua (2) hari bekerja, tidak termasuk hujung minggu/cuti am.',
      faq_ans_4:
        'Tidak, anda memerlukan insurans kenderaan yang sah untuk memperbaharui cukai jalan anda. Sila pastikan insurans anda dikemas kini sebelum cuba memperbaharui cukai jalan anda.',
      faq_ans_5:
        'Anda akan mendapat resit e-mel serta-merta selepas pembayaran dibuat dan anda akan menerima e-mel pengesahan dalam masa dua (2) hari bekerja dan anda boleh menyemak status pembaharuan pada aplikasi MyJPJ.',
      nearest_post_office: 'Pejabat Pos terdekat',
      faq_ans_6:
        'Anda mesti membayar semua denda lalu lintas yang tertunggak sebelum cukai jalan anda boleh diperbaharui. Sistem akan menyemak sebarang denda atau senarai hitam semasa proses pembaharuan.',
      faq_ans_7:
        'Jika kami tidak dapat memproses pembaharuan anda atas sebab selain daripada senarai hitam, kami akan membayar balik pembayaran cukai jalan kepada anda.',
      faq_ans_8:
        'Anda akan menerima pengesahan untuk bayaran balik dalam masa 7-14 hari bekerja.',
      faq_ans_9: 'Tidak, tiada yuran pemprosesan.',
      faq_ans_10:
        'Jika anda belum menerima pembaharuan anda dalam masa 2 hari bekerja, hubungi Khidmat Pelanggan Pos di 1-300-300-300 atau ',
      faq_ans_11:
        'Jika anda tidak menerima pembaharuan cukai jalan anda dalam masa 2 hari bekerja atau mempunyai pertanyaan lanjut, sila hubungi bahagian Perkhidmatan Pelanggan Pos kami untuk bantuan lanjut di 1-300-300-300 atau',
      faq_footer: 'Lanjutan Soalan Lazim',
      v_f_title: 'Jom, kenal anda',
      v_f_reg_no: 'No. Pendaftaran Kenderaan*',
      v_f_poscode: 'Poskod*',
      v_f_ic_type: 'Jenis ID*',
      v_f_ic_num: 'Nombor NRIC*',
      v_f_birthDate: 'Tarikh Lahir*',
      v_f_gender: 'Jantina*',
      v_f_gender_m: 'Lelaki',
      v_f_gender_f: 'Perempuan',
      v_f_marital: 'Status Perkahwinan*',
      v_f_marital_s: 'BUJANG',
      v_f_marital_m: 'BERKAHWIN',
      v_f_mobile: 'Nombor telefon*',
      v_f_b_label_1: 'Dengan mengklik butang “Seterusnya”, anda telah membaca',
      v_f_b_label_2: 'dan bersetuju dengan ',
      v_f_b_label_3: 'Terma Penggunaan ',
      v_f_b_label_4: ' Polisi Peribadi',
      v_f_b: 'Seterusnya',
      v_f_ic_error_1: 'Nombor NRIC anda diperlukan',
      v_f_ic_error_2: 'Sila masukkan nombor NRIC yang sah',
      v_f_ic_error_3: 'Nombor NRIC maks 12 aksara',
      v_f_ic_error_4: 'Nombor NRIC min 12 aksara',
      v_f_passport_error_1: 'Nombor pasport anda diperlukan',
      v_f_passport_error_2: 'Sila masukkan nombor pasport yang sah',
      v_f_passport_error_3: 'Nombor pasport maks 15 aksara',
      v_f_passport_error_4: 'Nombor pasport min 6 aksara',
      v_f_reg_no_error_1: 'Sila masukkan nombor kenderaan yang sah',
      v_f_reg_no_error_2: 'Nombor kenderaan diperlukan',
      v_f_reg_no_error_3: 'Nombor kenderaan maks 10 aksara',
      v_f_reg_no_error_4: 'Nombor kenderaan min 2 aksara',
      v_f_poscode_error_1: 'Poskod diperlukan',
      v_f_poscode_error_2: 'Sila masukkan nombor poskod yang sah',
      v_f_poscode_error_3: 'Poskod maks 5 aksara',
      v_f_birthDate_error: 'Tarikh lahir diperlukan',
      v_f_gender_error: 'Jantina diperlukan',
      v_f_marital_error: 'Status perkahwinan anda diperlukan',
      v_f_mobile_error_1: 'Nombor telefon diperlukan',
      v_f_mobile_error_2: 'Sila masukkan nombor telefon yang sah',
      v_f_mobile_error_3: 'Nombor telefon min 9 aksara',
      variant_error:
        'Kami tidak dapat mendapat varian kenderaan anda. Sila kunjungi cawangan Pos Malaysia terdekat anda untuk melakukan pembaharuan.',
      allianze_car_comprehensive_1:
        'Kerugian atau kerosakan akibat kemalangan, kebakaran dan kecurian',
      allianze_car_comprehensive_2:
        'Kematian pihak ketiga, kerosakan harta benda kecederaan badan',
      allianze_car_comprehensive_3: 'Perkhidmatan bantuan tepi jalan',
      allianze_car_third_party_1: 'Kematian pihak ketiga dan kecederaan badan',
      allianze_car_third_party_2: 'Kerugian atau kerosakan harta pihak ketiga',
      allianze_car_fire_theft_1:
        'Kerugian atau kerosakan akibat kebakaran atau kecurian',
      allianze_car_fire_theft_2: 'Kematian pihak ketiga dan kecederaan badan',
      allianze_car_fire_theft_3: 'Kerugian atau kerosakan harta pihak ketiga',
      allianze_motor_comprehensive_1:
        'Kerugian atau kerosakan akibat kemalangan, kebakaran dan kecurian',
      allianze_motor_comprehensive_2:
        'Kematian atau kecederaan badan pihak ketiga dan kerosakan harta pihak ketiga',
      allianze_motor_comprehensive_3: 'Perlindungan untuk situasi genting',
      allianze_motor_comprehensive_4:
        'Kemalangan Peribadi Percuma (motosikal dengan 250 cc ke bawah)',
      allianze_motor_comprehensive_5: 'Perkhidmatan bantuan tepi jalan',
      allianze_motor_comprehensive_6: 'Perlindungan Tambahan Bencana Khas',
      allianze_motor_comprehensive_7:
        'Pendapatan Hospital - Penunggang yang diinsuranskan/ penunggang sah dan pembonceng',
      allianze_motor_comprehensive_8:
        'Kematian/Hilang Upaya Kekal bagi penunggang yang diinsuranskan/penunggang sah dan pembonceng',
      allianze_motor_third_party_1:
        'Kematian pihak ketiga dan kecederaan badan',
      allianze_motor_third_party_2:
        'Kerugian atau kerosakan harta pihak ketiga',
      allianze_motor_third_party_3:
        'Pendapatan Hospital - Penunggang yang diinsuranskan/ penunggang sah dan pembonceng',
      allianze_motor_third_party_4:
        'Kematian/Hilang Upaya Kekal bagi penunggang yang diinsuranskan/penunggang sah dan pembonceng',
      allianze_motor_fire_theft_1:
        'Kerugian atau kerosakan akibat kebakaran atau kecurian',
      allianze_motor_fire_theft_2: 'Kematian pihak ketiga dan kecederaan badan',
      allianze_motor_fire_theft_3: 'Kerugian atau kerosakan harta pihak ketiga',
      zurich_g_car_comprehensive_1: 'Kecederaan Badan Dilindungi',
      zurich_g_car_comprehensive_2: 'Perlindungan Kenderaan Dilindungi',
      zurich_g_car_comprehensive_3: 'Kecederaan Badan Pihak Ketiga',
      zurich_g_car_comprehensive_4: 'Perlindungan Kenderaan Pihak Ketiga',
      zurich_g_car_comprehensive_5:
        'Penundaan Kerosakan Percuma sehingga 150KM',
      zurich_g_car_comprehensive_6: 'RM200 untuk Tunda Kemalangan',
      zurich_g_car_comprehensive_7: 'Pemandu Sah',
      zurich_g_car_third_party_1: 'Kecederaan Badan Pihak Ketiga.',
      zurich_g_car_third_party_2: 'Kenderaan Pihak Ketiga.',
      zurich_g_car_fire_theft_1: 'Kecederaan Badan Pihak Ketiga.',
      zurich_g_car_fire_theft_2: 'Kenderaan Pihak Ketiga.',
      zurich_g_car_fire_theft_3:
        'Kenderaan dari Kebakaran dan Kehilangan Kecurian.',
      zurich_g_motor_comprehensive_1: 'Kecederaan Badan Dilindungi',
      zurich_g_motor_comprehensive_2: 'Pembaikan Motosikal untuk Dilindungi',
      zurich_g_motor_comprehensive_3: 'Kecederaan Badan Pihak Ketiga',
      zurich_g_motor_comprehensive_4: 'Pembaikan Motosikal untuk Pihak Ketiga',
      zurich_g_motor_comprehensive_5: 'Kerugian Kecurian Kenderaan.',
      zurich_g_motor_comprehensive_6: 'Semua Penunggang',
      zurich_g_motor_third_party_1: 'Kecederaan Badan Pihak Ketiga Sahaja.',
      zurich_g_motor_third_party_2: 'Pembaikan Motosikal untuk Pihak Ketiga.',
      zurich_g_motor_fire_theft_1: 'Kecederaan Badan Pihak Ketiga Sahaja.',
      zurich_g_motor_fire_theft_2: 'Pembaikan Motosikal untuk Pihak Ketiga.',
      zurich_g_motor_fire_theft_3:
        'Kerugian Kebakaran dan Kecurian Disebabkan Pihak Ketiga',
      zurich_t_car_comprehensive_1: 'Kecederaan Badan Dilindungi',
      zurich_t_car_comprehensive_2: 'Perlindungan Kenderaan Dilindungi',
      zurich_t_car_comprehensive_3: 'Kecederaan Badan Pihak Ketiga',
      zurich_t_car_comprehensive_4: 'Perlindungan Kenderaan Pihak Ketiga',
      zurich_t_car_comprehensive_5:
        'Penundaan Kerosakan Percuma sehingga 150KM',
      zurich_t_car_comprehensive_6: 'RM200 untuk Tunda Kemalangan',
      zurich_t_car_comprehensive_7: 'Pemandu Sah',
      zurich_t_car_third_party_1: 'Kecederaan Badan Pihak Ketiga.',
      zurich_t_car_third_party_2: 'Kenderaan Pihak Ketiga.',
      zurich_t_car_fire_theft_1: 'Kecederaan Badan Pihak Ketiga.',
      zurich_t_car_fire_theft_2: 'Kenderaan Pihak Ketiga.',
      zurich_t_car_fire_theft_3:
        'Kenderaan dari Kebakaran dan Kehilangan Kecurian.',
      zurich_t_motor_comprehensive_1: 'Kecederaan Badan Dilindungi',
      zurich_t_motor_comprehensive_2: 'Pembaikan Motosikal untuk Dilindungi',
      zurich_t_motor_comprehensive_3: 'Kecederaan Badan Pihak Ketiga',
      zurich_t_motor_comprehensive_4: 'Pembaikan Motosikal untuk Pihak Ketiga',
      zurich_t_motor_comprehensive_5: 'Kerugian Kecurian Kenderaan.',
      zurich_t_motor_comprehensive_6: 'Semua Penunggang',
      zurich_t_motor_third_party_1: 'Kecederaan Badan Pihak Ketiga Sahaja.',
      zurich_t_motor_third_party_2: 'Pembaikan Motosikal untuk Pihak Ketiga.',
      zurich_t_motor_fire_theft_1: 'Kecederaan Badan Pihak Ketiga Sahaja.',
      zurich_t_motor_fire_theft_2: 'Pembaikan Motosikal untuk Pihak Ketiga.',
      zurich_t_motor_fire_theft_3:
        'Kerugian Kebakaran dan Kecurian Disebabkan Pihak Ketiga',
      tune_car_comprehensive_1:
        'Kerosakan pada kenderaan anda akibat kemalangan',
      tune_car_comprehensive_2:
        'Kehilangan/kerosakan pada kenderaan anda yang disebabkan oleh Kecurian atau Kebakaran',
      tune_car_comprehensive_3: 'Kerosakan kepada harta benda pihak lain',
      tune_car_comprehensive_4: 'Kematian atau kecederaan kepada pihak lain',
      tune_car_comprehensive_5: 'Pecah Cermin Depan',
      tune_car_comprehensive_6:
        'Auto-Bantuan Kecemasan 24 Jam (Tunda akibat Kemalangan & Kerosakan)',
      tune_car_comprehensive_7: 'Semua Perlindungan Pemandu',
      tune_car_fire_theft_1:
        'Kehilangan/kerosakan pada kenderaan anda yang disebabkan oleh Kecurian atau Kebakaran',
      tune_car_fire_theft_2: 'Kerosakan kepada harta pihak lain',
      tune_car_fire_theft_3: 'Kematian atau kecederaan kepada pihak lain',
      tune_motor_comprehensive_1:
        'Kerosakan pada kenderaan anda akibat kemalangan',
      tune_motor_comprehensive_2:
        'Kehilangan/kerosakan pada kenderaan anda yang disebabkan oleh Kecurian atau Kebakaran',
      tune_motor_comprehensive_3: 'Kerosakan kepada harta benda pihak lain',
      tune_motor_comprehensive_4: 'Kematian atau kecederaan kepada pihak lain',
      tune_motor_comprehensive_5: 'Liputan Semua Penunggang',
      tune_motor_third_party_1: 'Kerosakan harta benda pihak lain',
      tune_motor_third_party_2: 'Kematian atau kecederaan kepada pihak lain',
      rhb_car_comprehensive_1: 'Percuma 24/7 Penyedia Bantuan Kecemasan',
      rhb_car_comprehensive_2:
        "Percuma 'jumpstart', penukaran bateri, Perkhidmatan tukar tayar dan banyak lagi.",
      rhb_car_comprehensive_3:
        'Perkhidmatan tunda percuma sehingga RM300 untuk setiap kerosakan',
      rhb_car_comprehensive_4:
        'Perkhidmatan tunda percuma sehingga RM200 untuk kemalangan',
      rhb_car_fire_theft_1:
        'Perkhidmatan tunda percuma sehingga RM200 untuk kemalangan',
      rhb_car_fire_theft_2: 'Pembaikan kenderaan untuk pihak ketiga',
      rhb_motor_comprehensive_1:
        'Nikmati perlindungan ke atas semua penunggang',
      rhb_motor_comprehensive_2:
        'Pembaikan Motorsikal untuk pihak yang dilindungi dan pihak ketiga',
      rhb_motor_comprehensive_3:
        'Perkhidmatan tunda percuma sehingga RM50 untuk kemalangan',
      rhb_motor_comprehensive_4:
        'Lindungi motorsikal anda dengan perlindungan tambahan RHBI (bahaya khas, liabiliti kepada pembonceng)',
      rhb_motor_fire_theft_1: 'Nikmati perlindungan ke atas semua penungang',
      rhb_motor_fire_theft_2: 'Pembaikan Motorsikal untuk pihak ketiga',
      rhb_motor_fire_theft_3:
        'Perkhidmatan tunda percuma sehingga RM50 untuk kemalangan',
      etiqa_car_comprehensive_1:
        'Kecederaan tubuh badan dan kematian pihak ketiga',
      etiqa_car_comprehensive_2:
        'Kerugian atau kerosakan harta benda pihak ketiga',
      etiqa_car_comprehensive_3:
        'Kerugian atau kerosakan terhadap kenderaan anda akibat kebakaran, kecurian atau kemalangan',
      etiqa_car_comprehensive_4: 'Perlindungan untuk semua pemandu',
      etiqa_car_comprehensive_5:
        'Tiada ekses untuk tuntutan untuk pemandu 21 tahun ke atas',
      etiqa_car_comprehensive_6: 'Khidmat tunda percuma sehingga 200km',
      etiqa_car_comprehensive_7: 'Bantuan kecemasa 24-jam di jalan raya',
      etiqa_car_comprehensive_8:
        'Mudharabah (Nikmati perkongsian keuntungan bagi setiap polisi yang tiada tuntutan pada tahun sebelumnya)',
      etiqa_car_fire_theft_1:
        'Kecederaan tubuh badan dan kematian pihak ketiga',
      etiqa_car_fire_theft_2:
        'Kerugian atau kerosakan harta benda pihak ketiga',
      etiqa_car_fire_theft_3:
        'Kerugian atau kerosakan terhadap kenderaan anda akibat kebakaran, kecurian, atau kemalangan',
      etiqa_car_fire_theft_4:
        'Mudharabah (Nikmati perkongsian keuntungan bagi setiap polisi yang tiada tuntutan pada tahun sebelumnya)',
      etiqa_motor_comprehensive_1:
        'Perlindungan untuk motosikal sekiranya berlaku kemalangan, kebakaran dan kecurian, Perlindungan sekiranya berlaku kematian, kecederaan dan kerosakan harta benda pihak ketiga',
      etiqa_motor_comprehensive_2:
        "Pilihan untuk 'All Rider' atau 'Single Rider'",
      etiqa_motor_comprehensive_3: 'Khidmat tunda percuma sehingga 50km',
      etiqa_motor_comprehensive_4: 'Bantuan kecemasan 24-jam di jalan raya',
      etiqa_motor_comprehensive_5:
        'Mudharabah (Nikmati perkongsian keuntungan bagi setiap polisi yang tiada tuntutan pada tahun sebelumnya)',
      add_on: 'Servis Tambahan',
      addon_1: 'Perlindungan Peril Khas',
      addon_2: 'Pemandu Tanpa Had',
      addon_3: 'Pemandu Tambahan',
      addon_4: 'Cermin Depan',
      addon_5: 'Liabiliti Undang-undang Untuk Penumpang bagi Kecuaian',
      addon_6: 'Liabiliti Undang-undang kepada Penumpang',
      addon_7: 'Penundaan Tanpa Had',
      addon_8: 'Kereta Sewa Persendirian (E-hailing)',
      addon_9: 'Pemansuhan',
      addon_10: 'Pampasan untuk Masa Pembaikan yang Dinilai',
      addon_11: 'Banjir, Ribut & Taufan',
      allDrivers:
        'Pengecualian lebihan wajib bagi semua pemandu sekiranya berlaku tuntutan dengan syarat mereka memegang lesen memandu penuh yang sah dan berumur 21 tahun ke atas pada masa kejadian.',
      wsc: 'Perlindungan cermin depan membolehkan anda mendapatkan semula kos pembaikan atau penggantian cermin depan kenderaan anda. Manfaat tambahan ini melindungi anda daripada kehilangan NCD anda.',
      cvpp1cart:
        'Perlindungan cermin depan membolehkan anda mendapatkan semula kos pembaikan atau penggantian cermin depan kenderaan anda. Manfaat tambahan ini melindungi anda daripada kehilangan NCD anda.',
      flood:
        'Melindungi kerugian atau kerosakan pada kenderaan anda yang disebabkan oleh banjir, taufan, ribut, letusan gunung berapi, gempa bumi, tanah runtuh, penenggelaman atau tenggelamnya tanah/bumi atau gegaran alam yang lain.',
      llop: 'Melindungi anda daripada liabiliti undang-undang yang diminta oleh pihak ketiga terhadap anda atas tindakan penumpang anda di dalam kereta anda dengan syarat penumpang itu tidak memandu kereta anda dan syarat lain yang ditetapkan dipenuhi.',
      lltp: 'Melindungi anda terhadap liabiliti undang-undang yang diminta oleh penumpang anda (kecuali ahli keluarga anda sendiri) terhadap anda sekiranya berlaku kemalangan akibat kecuaian anda',
      ehailing:
        'Sediakan “Pelindung Lanjutan Tambahan” semasa Kereta Sedang Dihubungi sambil menyediakan Perkhidmatan E-Hailing.',
      untow2:
        'Penundaan Jarak tanpa had sehingga had yang ditetapkan di Malaysia.',
      waiver:
        'Mengetepikan caj penambahbaikan dalam kerja-kerja pembaikan kenderaan akibat kemalangan.',
      and: 'Pemansuhan lebihan wajib bagi pemandu yang dinamakan sekiranya berlaku tuntutan dengan syarat mereka memegang lesen memandu penuh yang sah dan berumur melebihi 21 tahun pada masa kejadian.',
      all_error_req:
        ' Kami tidak dapat meneruskan permintaan anda. Sila cuba sebentar lagi.',
      fl_error_sorry: 'Maaf!',
      fl_error_success: 'Berjaya!',
      fl_error_close: 'Tutup',
      fl_error_done: 'Selesai',
      fl_error_retry: 'Cuba lagi',
      fl_error_refered: 'Rujuk pejabat Pos untuk membuat pembaharuan.',
      mdm_postcode_error: 'Sila masukkan poskod yang sah.',
      pay_success: 'Pembayaran anda berjaya',
      insufficient_fund_error:
        'Transaksi anda telah gagal kerana baki tidak mencukupi. Sila tambah nilai akaun anda atau gunakan alternatif pembayaran lain untuk meneruskan pembayaran.',
      auth_failed_error:
        'Kami tidak dapat mengecaj kad anda. Sila gunakan kad lain atau kaedah pembayaran lain untuk meneruskan pembayaran.',
      user_abort: 'Kami kesal melihat anda pergi. Sila cuba lagi.',
      fl_error_code_3_31:
        'Polisi anda belum perlu diperbaharui lagi. Anda hanya boleh memperbaharui polisi anda dalam masa 2 bulan sebelum ia tamat tempoh. Kita jumpa lagi.',
      fl_error_code_8:
        'Butiran Kenderaan dan peribadi anda tidak sepadan. Sila cuba lagi.',
      fl_error_code_9_34:
        'Nombor kenderaan yang dimasukkan tidak wujud. Sila masukkan nombor kenderaan yang betul.',
      fl_error_code_33:
        'Kenderaan anda tidak boleh digunakan untuk pembaharuan insurans dalam talian. Sila kunjungi cawangan Pos Malaysia yang berhampiran untuk meneruskan pembaharuan.',
      fl_error_code_35_77:
        'Kami tidak dapat memperbaharui insurans kenderaan anda dalam talian. Sila kunjungi cawangan Pos Malaysia yang berhampiran untuk meneruskan pembaharuan.',
      referrel_case:
        'Kami tidak dapat meneruskan permintaan pembaharuan insurans jenis kenderaan khusus ini. Sila kunjungi pejabat Pos Malaysia berhampiran anda untuk memperbaharui insurans kenderaan anda.',
      faq_list_1:
        'Adakah saya perlu menyediakan IC  saya (atau sebarang bentuk ID peribadi lain iaitu pasport) untuk pembaharuan dalam talian?',
      faq_list_2: "Apakah 'NCD'?",
      faq_list_3: 'Mengapakah NCD yang dipetik berbeza daripada rekod saya?',
      faq_list_4:
        'Bagaimanakah saya menentukan Jumlah insuran untuk kereta saya?',
      faq_list_5: 'Apakah itu Liputan Nilai Pasaran? (Market Value Coverage?)',
      faq_list_6: 'Apakah Perlindungan Nilai yang Dipersetujui?',
      faq_list_7: 'Bolehkah saya menukar butiran dalam polisi?',
      faq_list_8: 'Apakah pengecualian utama untuk insuran Komprehensif Motor?',
      faq_list_9:
        'Apakah pengecualian utama untuk insuran Motosikal Komprehensif?',
      faq_list_10:
        'Adakah insuran saya melindungi saya jika saya menggunakan motosikal saya untuk syarikat penghantaran makanan (cth Foodpanda, Grabfood, Shopeefood, dll.)?',
      faq_list_11: 'Adakah insuran motosikal saya sah di luar Malaysia?',
      faq_list_12: 'Apakah itu detarrifikasi insuran motor?',
      faq_list_13:
        'Bagaimanakah syarikat insuran menentukan harga harga mereka?',
      faq_list_14:
        'Bagaimanakah detarrifikasi akan memberi manfaat kepada anda?',
      faq_list_15:
        'Gerbang Pembayaran manakah yang digunakan oleh Pos Malaysia?',
      faq_list_16:
        'PC saya beku selepas saya melakukan pembayaran dan kaedah pembayaran saya telah dicaj tanpa pemberitahuan pembayaran yang berjaya. Apa patut saya buat?',
      faq_list_17:
        'Apakah yang berlaku jika saya tidak mempunyai dana yang mencukupi dalam e-dompet, kad kredit atau debit saya?',
      faq_list_18:
        'Saya telah membuat pembayaran. Bagaimanakah saya boleh tahu bahawa pembayaran saya berjaya?',
      faq_list_19:
        'Siapa yang boleh saya hubungi untuk bertanya tentang status transaksi saya?',
      faq_list_20:
        'Adakah terdapat had kepada bilangan transaksi atau jumlah yang boleh saya buat dalam sehari?',
      faq_list_21:
        'Bolehkah saya membatalkan polisi insuran selepas pembayaran?',
      faq_list_22:
        'Bagaimanakah saya boleh mendapatkan bayaran balik selepas polisi saya dibatalkan?',
      faq_list_ans_1:
        'Secara amnya, anda tidak perlu memberikan nombor IC (Kad Pengenalan) anda untuk memperbaharui insuran kereta/motosikal anda dalam talian, melainkan syarikat insuran memintanya untuk tujuan pengesahan, seperti dalam kes di mana butiran kereta tidak betul atau tidak dikesan di dalam sistem JPJ (Jabatan Pengangkutan Jalan)',
      faq_list_ans_2:
        "'Diskaun Tanpa Tuntutan' atau 'NCD' ialah skim 'ganjaran' untuk anda jika tiada tuntutan dibuat ke atas polisi insuran anda setiap tahun. Kadar NCD yang berbeza digunakan untuk kelas kenderaan yang berbeza. Untuk kereta persendirian, skala NCD berjulat dari 25% hingga 55% seperti yang diperuntukkan dalam polisi.",
      faq_list_ans_3:
        'NCD yang disyorkan adalah berbeza jika terdapat tuntutan dibuat atau jika terdapat pengeluaran NCD untuk dipindahkan ke kereta lain yang diminta oleh yang dilindungi. Sebagai alternatif, anda juga boleh menyemak kadar NCD semasa anda melalui \n https://www.mycarinfo.com.my/NCDCheck/Online.',
      faq_list_ans_4:
        'Jumlah yang dilindungi hendaklah berdasarkan nilai pasaran kereta anda. Nilai Pasaran mewakili nilai kereta anda dengan elaun untuk kelusuhan dan/atau penyusutan nilai.',
      faq_list_ans_5:
        'Nilai Pasaran ialah jumlah berdasarkan anggaran syarikat insuran terhadap nilai kereta anda di pasaran terbuka. Sekiranya apa-apa berlaku kepada kereta anda, anda hanya boleh menuntut sebanyak nilai pasaran/nilai model kereta anda pada masa itu. Secara umumnya, harga perlindungan Nilai Pasaran lebih rendah.',
      faq_list_ans_6:
        'Nilai yang disetujui ialah jumlah yang telah dipersetujui antara anda dan syarikat insuran anda. Sekiranya berlaku apa-apa kepada kereta anda, perlindungan nilai yang dipersetujui sudah tentu boleh memberikan pampasan yang anda akan terima daripada syarikat insuran jika kereta anda dihapuskan atau dicuri. Secara umumnya, harga perlindungan Pasaran yang Dipersetujui lebih tinggi dan tertakluk kepada polisi insuran individu yang dibeli.',
      faq_list_ans_7:
        'Sila hubungi syarikat insuran anda dengan bukti perubahan.',
      faq_list_ans_8:
        'Polisi ini tidak melindungi kerugian tertentu (tertakluk kepada penanggung insuran masing-masing) seperti: \n 1. Kematian pemegang polisi atau kecederaan anggota badan akibat kemalangan motor;\n 2. Liabiliti pemegang polisi terhadap tuntutan daripada penumpang dalam kenderaan anda; dan \n 3. Kerugian, kerosakan atau liabiliti yang timbul daripada perbuatan alam iaitu banjir, ribut atau tanah runtuh.',
      faq_list_ans_9:
        "Polisi ini tidak melindungi kerugian tertentu seperti:\n 1. Kematian Pemegang Polisi atau kecederaan anggota badan akibat kemalangan motosikal; \n'-2. Liabiliti pemegang polisi terhadap tuntutan daripada penunggang lain; dan \n-3. Kerugian, kerosakan atau liabiliti yang timbul daripada perbuatan alam iaitu banjir, ribut atau tanah runtuh. (Nota: Senarai ini tidak lengkap. Sila rujuk kontrak polisi untuk senarai penuh pengecualian di bawah polisi ini.)",
      faq_list_ans_10:
        'Ya, kami menyediakan perlindungan insuran untuk mereka yang bekerja dengan syarikat perkhidmatan penghantaran, termasuk tetapi tidak terhad kepada Deliveroo, FoodPanda atau GrabFood, dsb.',
      faq_list_ans_11:
        'Jika anda bercadang untuk mengembara dengan motosikal anda di luar Malaysia, anda perlu membeli polisi insuran yang berbeza sebelum menyeberangi sempadan.',
      faq_list_ans_12:
        'Detarfikasi bermaksud menghapuskan struktur tarif (harga tetap). Ini akan membolehkan syarikat insuran mengenakan harga pada harga berbeza yang sepadan dengan tingkah laku risiko penggunanya berdasarkan model dan strategi risiko perniagaan. Penyahtarikan harga motor menawarkan produk motor yang lebih inovatif dan harga kompetitif.\n Sebelum 1 Julai 2017 - Harga untuk produk motor adalah tertakluk kepada kadar tarif yang sama berdasarkan kapasiti enjin dan lokasi., Fleksibiliti dalam penetapan harga berdasarkan tingkah laku risiko pemegang polisi. \n1 Julai 2017 dan seterusnya - Semua produk telah ditakrifkan dan tertakluk kepada kadar tarif, Penanggung insuran mempunyai fleksibiliti untuk menawarkan produk dan ciri baharu berdasarkan risiko pemegang polisi.',
      faq_list_ans_13:
        'Dengan penurunan tarif, setiap syarikat insuran akan mempunyai model penetapan harganya sendiri berdasarkan ciri-ciri berbeza pemandu dan kenderaan seperti jantina, umur pemandu, penggunaan kereta, pengalaman tuntutan, pekerjaan, pembuatan dan model kenderaan serta faktor lain yang berkaitan.Detarfikasi bermaksud menghapuskan struktur tarif (harga tetap). Ini akan membolehkan syarikat insuran mengenakan harga pada harga berbeza yang sepadan dengan tingkah laku risiko penggunanya berdasarkan model dan strategi risiko perniagaan. Penyahtarikan harga motor menawarkan produk motor yang lebih inovatif dan harga kompetitif. \n Sebelum 1 Julai 2017 - Harga untuk produk motor adalah tertakluk kepada kadar tarif yang sama berdasarkan kapasiti enjin dan lokasi., Fleksibiliti dalam penetapan harga berdasarkan tingkah laku risiko pemegang polisi. \n 1 Julai 2017 dan seterusnya - Semua produk telah ditakrifkan dan tertakluk kepada kadar tarif, Penanggung insuran mempunyai fleksibiliti untuk menawarkan produk dan ciri baharu berdasarkan risiko pemegang polisi.',
      faq_list_ans_14:
        'Sepintas lalu: Kadar harga yang berbeza kerana ia berdasarkan profil pemacu, model risiko perniagaan dan strategi. Rangkaian produk insuran motor penentuan harga berasaskan risiko yang lebih luas untuk memenuhi keperluan anda. Memanfaatkan penambahbaikan berterusan syarikat insuran termasuk pengalaman pelanggan, tahap perkhidmatan, keberkesanan operasi dan strategi pemasaran.',
      faq_list_ans_15:
        'Perkhidmatan pembayaran dalam talian kami disediakan dan dikendalikan oleh KiplePay',
      faq_list_ans_16:
        'Semua transaksi yang berjaya akan menerima e-mel pengesahan yang mengandungi butiran pembelian dan polisi insuran anda. Jika anda belum menerima sebarang e-mel, sila hubungi kami di 1-300-300-300 atau AskPos di www.pos.com.my/#AskPos untuk bantuan segera.',
      faq_list_ans_17: 'Transaksi anda akan ditolak.',
      faq_list_ans_18:
        'Setelah pembayaran berjaya, anda akan menerima pemberitahuan mengenai butiran pembayaran dan invois melalui alamat e-mel yang anda berikan.',
      faq_list_ans_19:
        'Sila hubungi bank atau pembekal eWallet untuk bertanya tentang status pembayaran. Untuk transaksi kad debit, anda boleh menyemak portal perbankan internet anda.',
      faq_list_ans_20:
        'Tiada had dalam amaun atau nombor transaksi setiap hari, namun had kad kredit atau debit adalah tertakluk kepada had atau baki anda yang tersedia dalam akaun kad kredit atau debit anda.',
      faq_list_ans_21:
        'Pembatalan polisi insuran adalah tertakluk kepada terma dan syarat pembekal insuran. Untuk pertanyaan lanjut, sila hubungi pembekal insuran di bawah untuk mendapatkan jawapan.',
      faq_list_ans_22:
        'Pembatalan pembayaran yang berkaitan dengan insuran sahaja akan diarahkan ke akaun bank yang dilindungi oleh Penanggung insuran.',
      bck_button: 'Kembali',
      p_type: 'Jenis Polisi',
      stepper1: 'Pelan',
      stepper2: 'Servis Tambahan',
      stepper3: 'Butiran Pelanggan',
      stepper4: 'Pembayaran',
      mandatory: '*mandatori',
      mandatory_desc:
        'Nota: Sila tambahkan alamat e-mel yang sah untuk menerima polisi insurans anda yang lengkap.',
      renew_now: 'Perbaharui Sekarang',
      policy_info: 'Maklumat Polisi',
      ncd_info_1: 'Hooray! Anda mempunyai ',
      ncd_info_2: 'Diskaun Tanpa Tuntutan (NCD) pada pembaharuan anda',
      info_pop:
        'Diskaun Tanpa Tuntutan (NCD) ialah diskaun yang ditawarkan oleh syarikat insurans jika anda (yang diinsuranskan) tidak membuat sebarang tuntutan terhadap polisi insurans kenderaan anda pada tahun lalu.',
      policy_duration: 'Tempoh Polisi Semasa',
      vehicle_info: 'Maklumat Kenderaan',
      vehicle_reg: 'Nombor Pendaftaran Kenderaan',
      vehicle_make: 'Buatan Kenderaan',
      vehicle_var: 'Sahkan Varian Kenderaan',
      engine_num: 'Nombor Enjin',
      chasis_num: 'Nombor Chasis',
      cubic_cap: 'Kapasiti Kubik',
      prod_year: 'Tahun Pembuatan',
      your_vehicle: 'Adakah ini kenderaan anda?',
      yes: 'Ya',
      no: 'Tidak',
      plan: 'Pelan',
      policy_type: 'Jenis Polisi',
      comprehensive: 'Komprehensif',
      third_party: 'Pihak ketiga',
      third_party_ft: 'Pihak Ketiga (Kebakaran dan Kecurian)',
      comprehensive_info:
        'Melindungi kenderaan pemegang polisi sekiranya berlaku kemalangan, kebakaran dan kecurian. Polisi ini juga menyediakan perlindungan kepada pihak ketiga.',
      third_party_info:
        'Memberi perlindungan hanya kepada pihak ketiga untuk kerosakan atau kerugian kepada pihak ketiga dan harta benda mereka yang disebabkan oleh kenderaan anda.',
      third_party_ft_info:
        'Melindungi hanya kerosakan dan kerugian kepada pihak ketiga di mana anda bersalah. Ia juga menyediakan perlindungan kepada kenderaan pemegang polisi sekiranya berlaku kebakaran dan kecurian.',
      sort: 'Susunan',
      default: 'Asal (A-Z)',
      price_up: 'Harga: Rendah ke Tinggi',
      price_down: 'Harga: Tinggi ke Rendah',
      benefit_empty: 'Tiada Faedah',
      Done: 'Selesai',
      your_saving: 'Simpanan anda:',
      error_no_info: 'Ralat; tidak dapat menerima maklumat',
      sum_insured: 'Jumlah Diinsuranskan',
      select_all: 'Pilih semua yang disyorkan',
      driver: 'Pemandu',
      coverage: 'Liputan',
      edit: 'Edit',
      premium_detail: 'Butiran Premium',
      coverage_period: 'Tempoh Perlindungan: ',
      basic: 'Premium Asas/Sumbangan',
      gross: 'Premium Kasar/Sumbangan',
      rebate: 'Jumlah Rebat',
      stamp_duty: 'Duti Setem',
      total: 'Jumlah',
      next: 'Seterusnya',
      as_of: 'Setakat',
      as_of_2: 'nilai perlindungan anda ialah RM ',
      confirm_coverage: 'Sahkan nilai perlindungan pilihan anda',
      market_value: 'Nilai pasaran',
      market_value_info:
        'Penanggung insurans / pembekal takaful anda membayar tuntutan anda berdasarkan nilai pasaran kereta anda semasa membuat tuntutan. Jumlah yang diinsuranskan / dilindungi tertakluk kepada susut nilai.',
      additional_driver: 'Pemandu Tambahan',
      additional_driver_info:
        'Pengecualian lebihan wajib bagi pemandu yang dinamakan sekiranya berlaku tuntutan',
      max_driver:
        'Bilangan anda telah mencapai maksimum (3/3) pemandu yang dinamakan. Adakah anda ingin bertukar kepada',
      unlimited: 'Pemandu Tanpa Had',
      add_driver: 'Tambah Pemandu',
      coverage_title:
        'Perlindungan untuk Cermin Depan, Tingkap dan Cermin Bumbung',
      coverage_title_info:
        'Membiayai kos untuk membaiki atau menggantikan mana-mana kaca di cermin depan, tingkap atau Cermin Bumbung (termasuk kos filem laminasi/pewarna, jika ada) kereta anda yang rosak secara tidak sengaja.',
      coverage_title_info_2:
        'Sila pastikan nilai perlindungan adalah di antara RM 500 hingga RM 5,000 sahaja.',
      coverage_value: 'Nilai Liputan',
      payable_amt: 'Jumlah yang perlu saya bayar',
      confirm: 'Sahkan',
      full_name: 'Nama Penuh Pemilik',
      email: 'Emel',
      mobile: 'Nombor Telefon',
      marital_status: 'Status Perkahwinan',
      current_address: 'Alamat Semasa',
      address: 'Alamat',
      postcode: 'Poskod',
      state: 'Negeri',
      City: 'Bandar',
      name_required: 'Nama perlu diisi',
      email_required: 'Emel perlu diisi',
      email_message: 'Sila masukkan emel yang sah',
      address_required: 'Alamat perlu diisi',
      confirm_customer:
        'Berikut ialah Butiran Pelanggan dan Kenderaan anda yang disahkan',
      vehicle_details: 'Butiran Kenderaan',
      owner: 'Pemilik',
      pay: 'Bayar',
      pay_info:
        "Dengan mengklik butang 'Bayar', anda dengan ini secara nyata bersetuju untuk berkongsi Data Peribadi anda tertakluk kepada perkara berikut",
      terms: 'Terma dan Syarat',
      pay_info_2: 'terima Rakan Kongsi, baca dan fahami',
      product_disclosure: 'Lembaran Pendedahan Produk',
      product_disclosure2: 'Dasar Polisi Zurich',
      pay_info_3:
        'oleh Rakan Kongsi dan mengakui anda tidak mempunyai 2 atau lebih tuntutan dalam tempoh 3 tahun yang lalu.',
      back: 'Kembali',
      error1:
        'Maaf! Kami tidak dapat meneruskan permintaan anda. Sila cuba sebentar lagi.',
      error2:
        'Maaf! Butiran Kenderaan dan peribadi anda tidak sepadan. Sila cuba lagi.',
      error3:
        'Maaf! Polisi anda belum perlu diperbaharui lagi. Anda hanya boleh memperbaharui polisi anda dalam masa 2 bulan sebelum ia tamat tempoh. Kita jumpa lagi.',
      error4:
        'Maaf! Kami tidak dapat memperbaharui insurans kenderaan anda dalam talian. Sila kunjungi cawangan Pos Malaysia yang berhampiran untuk meneruskan pembaharuan.',
      error5: 'Poskod tidak sah. Sila masukkan poskod yang sah.',
      error6: 'Maaf, tiada servis tambahan \nyang sepadan dengan pilihan anda',
      error7: 'Maaf, tiada pelan \nyang sepadan dengan pilihan anda',
      error8:
        'Sila gunakan butang kembali di bawah dan bukan butang kembali pelayar untuk memastikan ruangan yang anda masukkan sebelum ini kekal.',
      error9:
        'Anda ingin kembali? Anda akan dialihkan ke halaman utama dan kehilangan semua perubahan.',
      error10:
        'Maaf! Nombor kenderaan yang dimasukkan tidak wujud. Sila masukkan nombor kenderaan yang betul.',
      error11:
        'Maaf! Kami tidak dapat memperbaharui insurans kenderaan anda dalam talian. Sila kunjungi cawangan Pos Malaysia yang berhampiran untuk meneruskan pembaharuan.',
      Remove: 'Padam',
      loading_msg: 'Kami mencari tawaran insurans \n terbaik untuk anda...',
      loading_otw:
        'Tunggu.. Kami bergerak pantas untuk memandu anda ke \n destinasi seterusnya.',
      pay_attention:
        "Sila tunggu! Jangan muat semula halaman atau klik butang 'Kembali' atau 'Tutup' penyemak imbas anda semasa kami memproses pembayaran.",
      attention: 'Perhatian!',
      recommended_coverage: 'Perlindungan yang disyorkan',
      lowest_coverage: 'Perlindungan terendah tersedia',
      add_driver_name: 'Nama Penuh*',
      add_driver_error1: 'Input ini diperlukan.',
      add_driver_error2: 'Masukkan minimum 3 aksara',
      no_variant:
        'Kami tidak mendapat varian kenderaan anda. Sila ke cawangan Pos Malaysia yang berhampiran untuk melakukan pembaharuan insuran.',
      expired:
        'Maaf, sesi anda telah tamat tempoh kerana tidak aktif. Sila cuba lagi untuk meneruskan.',
      not_available:
        'Produk tambahan ini tidak tersedia untuk kenderaan anda. Sila pilih yang lain.',
      payment_msg_1: 'Terima kasih atas pembayaran anda.',
      payment_msg_2:
        'Salinan resit pembayaran akan dihantar ke alamat e-mel anda. Anda akan menerima e-mel berasingan selepas pembaharuan berjaya dengan arahan untuk menyemak status melalui aplikasi My JPJ.',
      payment_msg_3:
        'insurans anda akan dihantar ke e-mel anda dalam masa 10 minit.',
      your_id: 'ID rujukan pembayaran anda ialah',
      car_insurance_renewal: 'Perbaharui Insurans Kereta',
      motor_insurance_renewal: 'Perbaharui Insurans Motosikal',
      car_type_1: 'Ingin',
      car_type_2: 'memperbaharui Insurans',
      car_type_3: 'Pastikan insuran motor anda diperbaharui dan aktif.',
      renew1: 'Sudahkah anda memperbaharui insurans kenderaan anda?',
      renew2:
        'Pastikan insurans diperbaharui dan saman diselesaikan sebelum memperbaharui cukai jalan.',
      renew3: 'Saya ingin memperbaharui insurans dahulu',
      renew4: 'Teruskan dengan pembaharuan cukai jalan',
      renew5: 'Tiada padanan untuk nombor cukai jalan anda',
      renew6: 'Sila cuba lagi.',
      renew7: 'Tidak, saya ingin memperbaharui insurans dulu',
      contact1: 'Sila pilih jenis kenderaan anda',
      'Non-Saloon': 'Bukan Saloon',
      Saloon: 'Saloon',
      contact2: 'Sila beritahu butiran hubungan anda',
      contact3:
        'Selepas berurus niaga menggunakan kaedah ini, anda WAJIB memuat turun dan mendaftar aplikasi MyJPJ anda.',
      contact4:
        'Cukai jalan anda akan tersedia dalam aplikasi MyJPJ anda dalam tempoh masa 2 hari bekerja. (Bagi rakyat Malaysia, cukai jalan yang diperbaharui boleh dilihat melalui aplikasi MyJPJ sahaja)',
      contact5: 'Muat turun Aplikasi MyJPJ daripada',
      or: 'atau',
      price1: 'Butiran Cukai Jalan',
      price2: 'Kaedah Penerimaan',
      Duration: 'Tempoh',
      year: 'tahun',
      Method: 'Kaedah',
      price3: 'Cukai Jalan Digital (e-LKM)',
      price4: 'Bayaran Perkhidmatan',
      summary1: 'Berikut ialah butiran kenderaan dan pelanggan yang disahkan',
      summaryTnC:
        "Dengan mengklik butang 'Bayar', anda telah bersetuju untuk berkongsi Data Peribadi anda tertakluk kepada",
      summaryTnC2: 'Terma dan Syarat berikut.',
      summaryTnC3:
        'Saya mengakui dan mengesahkan bahawa kenderaan ini tidak digunakan untuk perkhidmatan e-hailing, tiada saman trafik tertunggak, dan memenuhi semua syarat pembaharuan yang diperlukan.',
      'Vehicle Owner’s Name': 'Nama Pemilik Kenderaan',
      'Vehicle Owner’s NRIC': 'NRIC Pemilik Kenderaan',
      'Vehicle Owner’s Passport Number': 'Vehicle Owner’s Passport Number',
      'Vehicle Registration Number': 'Nombor Pendaftaran Kenderaan',
      'Vehicle Details': 'Butiran Kenderaan',
      'Mobile Number': 'Nombor Telefon',
      'Email Address': 'Alamat Emel',
      'Vehicle Type': 'Jenis Kenderaan',
      important: 'Penting',
      try_again: 'Cuba lagi',
      'MPV / SUV / Pick-up / Commercial': 'MPV / SUV / Pick-up / Komersil',
      'Sedan / Hatchback / Wagon / Coupe / Convertible':
        'Sedan / Hatchback / Wagon / Coupe / Convertible',
      enter_full_name: 'Sila isi nama penuh',
      rt_breakdown: 'Pecahan Cukai Jalan',
      askpos: 'AskPos'
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
