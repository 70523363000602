import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'flag-icon-css/css/flag-icons.min.css';
import './assets/styles/index.scss';
import './utils/i18n';
import App from './App';

const loadingMarkup = (
  <div className="py-4 text-center">
    <h2>Loading...</h2>
  </div>
);
const root = createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={loadingMarkup}>
    <App />
  </Suspense>
);
