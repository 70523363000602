import React from 'react';
import { useLocation } from 'react-router-dom';

import './footer.scss';
import { Logo } from '..';
import { drbh } from 'assets/images';
import { listing } from 'constants/enum';
import dataLayer from 'hooks/DataLayerPush';
import { useStepper } from 'hooks/Stepper';
import { Translate } from 'hooks/Translation';

function Footer() {
  const { pathname } = useLocation();
  const currentPathname = pathname.split('/')[1];
  const { sendEventData } = dataLayer();
  const { redirectTo } = useStepper();

  return (
    <>
      {currentPathname === '' && (
        <div className="footer">
          <div className="upper-footer">
            <UpperFooter />
          </div>
          <div className="footerContainer">
            <div className="pos col-sm-2 col-md-2 mb-5">
              <div
                data-event={'go_to_home'}
                data-event-category={'Footer'}
                data-event-action={'Go To Home'}
                data-event-label={'Home'}
                onClick={(e) => {
                  sendEventData(e);
                  redirectTo('https://www.pos.com.my');
                }}
              >
                <Logo width={159.99} height={68.66} />
              </div>
              <div className="mt-5 drhb">
                <p className="mb-0">A member of</p>
                <img src={drbh} alt="drhb" />
              </div>
              <div></div>
            </div>
            <div className="list col-sm-8 col-md-8">
              <Lists items={listing} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Footer;

function Lists({ items }) {
  const { sendEventData } = dataLayer();
  return (
    <div className="row">
      {items.map((item, index) => (
        <ul className="col-lg-3 col-md-6 col-sm-6 col-6" key={index}>
          <li key={index} className="footer-title-list mb-4 medium">
            {item.title}
          </li>
          <ul>
            {item.data.map((c, i) => (
              <li key={i} className="footer-item-list my-2">
                <a
                  href={c.link}
                  data-event={'footer_menu'}
                  data-event-category={'Footer'}
                  data-event-action={'Click Footer Menu'}
                  data-event-label={c.name}
                  onClick={sendEventData}
                >
                  {c.name}
                </a>
              </li>
            ))}
          </ul>
        </ul>
      ))}
    </div>
  );
}

const UpperFooter = () => {
  const { t } = Translate();
  const { sendEventData } = dataLayer();

  return (
    <>
      <div className="d-flex flex-wrap justify-content-center">
        <h2 className="upper-footer-h2 mt-2">{t('chat_title_1')}</h2>

        <a
          href="https://www.pos.com.my/contact-us#AskPos"
          target="_blank"
          rel="noreferrer"
          data-event={'go_to_page'}
          data-event-category={'Road Tax - Home'}
          data-event-action={'Go To Page'}
          data-event-label={'Chat with us'}
          onClick={sendEventData}
        >
          <div className="chat mt-2 mt-sm-0 medium">{t('chat_title_2')}</div>
        </a>
      </div>
    </>
  );
};
