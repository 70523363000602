import { useEffect } from 'react';

export const useDisableEvents = () => {
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      const forbiddenKeys = [
        'F1',
        'F2',
        'F3',
        'F4',
        'F5',
        'F6',
        'F7',
        'F8',
        'F9',
        'F10',
        'F11',
        'F12'
      ];

      // Allow Ctrl+V / Command+V for pasting
      if ((e.ctrlKey || e.metaKey) && e.code === 'KeyV') {
        return true;
      }

      // Allow Ctrl+C / Command+C for copying
      if ((e.ctrlKey || e.metaKey) && e.code === 'KeyC') {
        return true;
      }

      // Allow Ctrl+A / Command+A for selecting all
      if ((e.ctrlKey || e.metaKey) && e.code === 'KeyA') {
        return true;
      }

      if ((e.ctrlKey || e.metaKey) && e.code !== 'KeyR' && e.code !== 'F5') {
        e.preventDefault();
        return false;
      }

      if (forbiddenKeys.includes(e.key)) {
        e.preventDefault();
        return false;
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
};

export default useDisableEvents;
